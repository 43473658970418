import axios from "axios";
import { tickerAPI } from "../../utils/config";

export const tickerApiService = axios.create({
    baseURL: `${tickerAPI}`,
});

export const CurrentCompanyData = async (token) => {
    return await tickerApiService.get(`/tick-data/by-token?token=${token}`).then((res) => { return res }).catch((err) => { return err })
};

export const ITNiftyData = async () => {
    return await tickerApiService.get("/tick-data/by-token?token=259849").then((res) => { return res }).catch((err) => { return err })
};

export const BankNiftyData = async () => {
    return await tickerApiService.get("/tick-data/by-token?token=260105").then((res) => { return res }).catch((err) => { return err })
};

export const NiftyData = async () => {
    return await tickerApiService.get("/tick-data/by-token?token=256265").then((res) => { return res }).catch((err) => { return err })
};

export const IndexMoversData = async (symbol, CMP, timeFrame) => {
    return await tickerApiService.get(`/${symbol}?currentIndexPrice=${CMP}&timeframe=${timeFrame}`).then((res) => { return res }).catch((err) => { return err })
};
// https://ticker.tradabulls.com/NIFTY50/sectors?currentIndexPrice=73299&timeframe=D

export const IndexMoversSectorsData = async (symbol, CMP, timeFrame) => {
    return await tickerApiService.get(`/${symbol}/sectors?currentIndexPrice=${CMP}&timeframe=${timeFrame}`).then((res) => { return res }).catch((err) => { return err })
};
export const HeatsMapData = async (a) => {
    return await tickerApiService.get(`/${a}/changePercentages`).then((res) => { return res }).catch((err) => { return err })
};

export const NiftyPlusData = async (tf) => {
    return await tickerApiService.get(`/niftyplus?currentIndexPrice=0&timeframe=${tf}`)
}
export const NsePlusData = async (tf) => {
    return await tickerApiService.get(`/nseplus?currentIndexPrice=0&timeframe=${tf}`)
}

export const NiftyTenData = async (tf) => {
    return await tickerApiService.get(`/niftyten?currentIndexPrice=0&timeframe=${tf}`)
}

export const GetHeatmapsObservations = async (indice, h_m_keyForApi) => {
    return await tickerApiService.get(`/${indice}/${h_m_keyForApi}`)
}

export const GetObservationsForCandle = async (tf, company, token) => {
    let encodedSymbol = encodeURIComponent(company);
    return await tickerApiService.get(`with-arrows?timeframe=${tf}&symbol=${encodedSymbol}&token=${token}`)
}

export const IndexFuturesData = async (tf) => {
    return await tickerApiService.get(`/futures?currentIndexPrice=0&timeframe=${tf}`)
}

export const IndexMoversBankTenData = async (tf) => {
    return await tickerApiService.get(`/bankten?currentIndexPrice=0&timeframe=${tf}`)
}
export const IndexMoversItTenData = async (tf) => {
    return await tickerApiService.get(`/itniftyten?currentIndexPrice=0&timeframe=${tf}`)
}

export const IndexMoversMidCapSelectTenData = async (tf) => {
    return await tickerApiService.get(`/midcapselectten?currentIndexPrice=0&timeframe=${tf}`)
}

export const IndexMoversFinNiftyTenData = async (tf) => {
    return await tickerApiService.get(`/finniftyten?currentIndexPrice=0&timeframe=${tf}`)
}

export const NiftyPlusPlus = async (indice, token) => {
    let encodedSymbol = encodeURIComponent(indice);
    return await tickerApiService.get(`/nseplusplus?symbol=${encodedSymbol}&token=${token}`)
}

export const candleGraphFor15min = async (token, fromDate, toDate, min) => {
    return await tickerApiService.get(`/fetch?instrumentToken=${token}&fromDate=${fromDate}+09:10:00&toDate=${toDate}+16:13:00&interval=${min}minute`)
}
export const updatedCandleGraph = async (token, min) => {
    return await tickerApiService.get(`candles/${token}?interval=${min}`)
}

export const candleGraphApi = async (token, fromDate, toDate, min) => {
    return await tickerApiService.get(`/fetch?instrumentToken=${token}&fromDate=${fromDate}+09:10:00&toDate=${toDate}+16:13:00&interval=${min}minute`)
}

export const fetchThreeRangeBand = async (tenDaysBefore, currentDate, token) => {
    return await tickerApiService.get(`/api/atr/calculate?period=3&interval=day&startTime=${tenDaysBefore}%2008:15:00&endTime=${currentDate}%2018:15:00&instrumentToken=${token}`)
}

export const fetchOptioOi = async (selectedIndex, range, w, date) => {
    if (w === "weekly") return await tickerApiService.get(`symbolsRangenew/options/weekly?expiryDate=${date}&tickersymbol=${selectedIndex}&range=${range}`)
    return await tickerApiService.get(`symbolsRangenew?symbols=${selectedIndex}&range=${range}`)
}

export const oiChangeWeeklyApi = async (symbol, range, date) => {
    return await tickerApiService.get(`/symbolsRangenew/oichange/weekly?expiryDate=${date}&tickersymbol=${symbol}&range=${range}`)
}

export const oiChangeMonthlyApi = async (symbol, range) => {
    return await tickerApiService.get(`/symbolsRangenew/oichange/monthly?tickersymbol=${symbol}&range=${range}`)
}

export const oichangeGetWeeklyDatesApi = async () => {
    return await tickerApiService.get('/options/monthly/dates')
}

export const getCmpNew = async () => {
    return await tickerApiService.get('/indices/futures/prices')
}
export const optionsGetWeeklyDatesApi = async (a,b,c,d) => {
    return await tickerApiService.get(`/symbolsRangenew/oichange/${b}?expiryDate=${d}&tickersymbol=${a}&range=${c}`)
}

export const superTrendsDatesApi = async (payload) => {
    return await tickerApiService.get(`api/atr/calculate/st/${payload}`)
}

export const superTrendsWeeklyDatesApi = async (payload) => {
    return await tickerApiService.get(`/api/atr/calculate/st/weekly/${payload}`)
}

export const superTrendsMonthlyDatesApi = async (payload) => {
    return await tickerApiService.get(`api/atr/calculate/st/monthly/${payload}`)
}

export const fetchImbalance = async (payload) => {
    return await tickerApiService.get(`imbalance?expiryDate=${payload}&tickersymbol=NIFTY50&range=250`)
}

export const fetchAllTimeHigh = async (payload) => {
    return await tickerApiService.get(`alltimehigh/${payload}`)
}

export const fetchAllindicesData = async (payload) => {
    return await tickerApiService.get(`marketoutlook`)
}

export const fetchAllTrendsForExpertView = async (currentDate,fiftenDaysBefore,companySymbol) => {
    return await tickerApiService.get(`trend?symbol=${companySymbol}&fromDate=${fiftenDaysBefore}&toDate=${currentDate}`)
}

export const fetchAllTrendsForFutureExpertView = async (currentDate,fiftenDaysBefore,companySymbol) => {
    return await tickerApiService.get(`trend/futures?symbol=${companySymbol}&fromDate=${fiftenDaysBefore}&toDate=${currentDate}`)
}

export const fetchAllCompaniesTrend = async (currentDate,previousDay) => {
    return await tickerApiService.get(`/trends/topcompanies?index=nifty50&fromDate=${previousDay}&toDate=${currentDate}`)
}

export const fetchAllHeatMapTrend = async (currentDate,previousDay,label) => {
    return await tickerApiService.get(`/trends?index=${label}&fromDate=${previousDay}&toDate=${currentDate}`)
}
// export const fetchAllInsightsTrend = async (index,currentDate,previousDay,currentDate_r,previousDay_r) => {
//     return await tickerApiService.get(`/trends/topcompanies/sorted?index=${index}&fromDate=${previousDay}&toDate=${currentDate}&rangefromDate=${previousDay_r}&rangetoDate=${currentDate_r}`)
// }

export const fetchAllInsightsTrend = async () => {
    return await tickerApiService.get(`/insights`)
}
export const fetchAllInsightsFutureTrend = async (currentDate,previousDay,currentDate_r,previousDay_r) => {
    return await tickerApiService.get(`/trends/topcompanies/futures/sorted?fromDate=${previousDay}&toDate=${currentDate}&rangefromDate=${previousDay_r}&rangetoDate=${currentDate_r}`)
}

export const fetchAllFuturesHeatmaps = async (currentDate,fiftenDaysBefore) => {
    return await tickerApiService.get(`/trends/futures?fromDate=${fiftenDaysBefore}&toDate=${currentDate}`)
}
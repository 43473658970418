import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './chart.css';
import { Box, Container, Typography } from '@material-ui/core';
import SingleCandleStick from './SingleCandleStick2';

const IndexMoversSectorsChart = (props) => {
  const { label, _filterLabel, cmp, tf, stockData, loading } = props;
  // const [stockData, setData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const totalValue = stockData?.reduce((sum, item) => sum + Math.abs(item?.contribution), 0);

  const getPercentage = (value) => {
    const scaleFactor = 6; // Increase this factor to make the bars longer
    return totalValue > 0 ? (Math.abs(value) / totalValue) * 100 * scaleFactor : 0;
  };

  const calculateTotalContribution = (a) => {
    return a?.reduce((total, item) => total + item?.contribution, 0);
  };

  const totalStocks = stockData?.length
  const positiveStocks = stockData?.filter(item => item?.contribution > 0).sort((a, b) => b.contribution - a.contribution);
  const negativeStocks = stockData?.filter(item => item?.contribution < 0).sort((a, b) => a.contribution - b.contribution);

  const p_totalContribution = calculateTotalContribution(positiveStocks);
  const n_totalContribution = calculateTotalContribution(negativeStocks);

  return (
    <Container >
      {/* <Typography variant="h4" className="header">Nifty 50 Dashboard</Typography> */}
      {loading ? (
        <Typography className="loading">Loading...</Typography>
      ) : error ? (
        <Typography className="error">{error}</Typography>
      ) : (
        <Box className="stock-container">
          <Box className="stock-box">
            <Box className="stock-list">
              <Typography variant="h6" gutterBottom style={{ justifyContent: 'end', display: 'flex', color: '#f44336', alignItems: 'center', marginRight: '10px', fontSize: '14px', fontWeight: 'bold', whiteSpace: "nowrap" }}>
                <span style={{ fontSize: '11px', marginRight: '5px', fontWeight: 'bold', color: 'red' }}>({negativeStocks?.length}/{totalStocks})</span> Negative Contributors ({n_totalContribution?.toFixed(2)})
              </Typography>
              {negativeStocks?.map(item => (
                <Box key={item?.sectorName} className="stock-item stock-item-p">
                  <>{console.log('item...', item)}</>
                  <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <Typography variant="body2" className="medium-text">
                      <span className='font-futura font-black mr-1' style={{ fontSize: "9px", color: "red" }}>{item?.currentprice?.toFixed(2)} ({Number.isFinite(item?.sectorDifference) ? item?.sectorDifference?.toFixed(2) : '0'})({Number.isFinite(item?.changePercentage) ? item?.changePercentage?.toFixed(2) : "0"}%)</span>
                      <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{item?.sectorName}</span>
                      <span className="stock-price" style={{ color: 'red', marginLeft: '3px', fontSize: '10px' }}>
                        ({item.contribution.toFixed(2)})
                      </span>
                    </Typography>
                    {/* </Box> */}
                    <Box className="bar negative-stock-bar" style={{ width: `${getPercentage(item.contribution)}px` }} />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className="stock-list">
              <Typography variant="h6" gutterBottom style={{ justifyContent: 'start', display: 'flex', color: '#339249', alignItems: 'center', marginLeft: '10px', fontSize: '14px', fontWeight: 'bold', whiteSpace: "nowrap" }}>
                ({p_totalContribution?.toFixed(2)}) Positive Contributors<span style={{ fontSize: '11px', marginLeft: '5px', fontWeight: 'bold', color: 'green' }}>({positiveStocks?.length}/{totalStocks})</span>
              </Typography>
              {positiveStocks?.map(item => (
                <Box key={item?.sectorName} className="stock-item stock-item-n">
                  {/* <Box className='flex'> */}
                  <Box className="bar positive-stock-bar" style={{ width: `${getPercentage(item.contribution)}px` }} />
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body2" className="medium-text">
                      <span className="stock-price" style={{ color: 'green', marginRight: '3px', fontSize: '10px' }}>
                        (+{item.contribution.toFixed(2)})
                      </span>
                      <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{item?.sectorName}</span>
                      <span className='font-futura font-black ml-1' style={{ fontSize: "9px", color: "green" }}>{item?.currentprice?.toFixed(2)} ({item?.sectorDifference?.toFixed(2)})({item?.changePercentage?.toFixed(2)}%)</span>
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};
export default IndexMoversSectorsChart;

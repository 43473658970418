// import React from 'react';
// import IndicesStrip from '../common/IndicesStrip';
// import './miscellaneous.css';
// import IndiceStripNew from '../common/IndiceStripNew';

// const CenteredSpiralSquareTable = ({ count = 441 }) => {
//     const getBlueShade = (index, total) => {
//         const maxLightness = 240; // Maximum lightness for a light blue gradient
//         const lightness = Math.floor(maxLightness - (maxLightness / total) * index);
//         return `rgb(173, 216, ${lightness})`;
//     };

//     const generateCenteredSpiralData = (count) => {
//         const data = [];
//         let x = 0, y = 0; // Start at the center
//         let dx = 0, dy = -1;
//         const positionMap = {};

//         const maxDimension = Math.ceil(Math.sqrt(count)) * 2; // Ensure enough space for a spiral
//         for (let i = 1; i <= count; i++) {
//             positionMap[`${x},${y}`] = i * i; // Assign sequential values instead of squares

//             // Change direction when needed in the spiral
//             if (x === y || (x < 0 && x === -y) || (x > 0 && x === 1 - y)) {
//                 const temp = dx;
//                 dx = -dy;
//                 dy = temp;
//             }
//             x += dx;
//             y += dy;
//         }

//         // Convert position map into a 2D array for display
//         const maxRadius = Math.ceil(maxDimension / 2);
//         for (let row = -maxRadius; row <= maxRadius; row++) {
//             const rowData = [];
//             for (let col = -maxRadius; col <= maxRadius; col++) {
//                 rowData.push(positionMap[`${col},${row}`] || null);
//             }
//             data.push(rowData);
//         }

//         // Trim rows and columns with all null values to create a centered spiral
//         const filteredData = data.filter(row => row.some(cell => cell !== null));
//         const transposedData = filteredData[0].map((_, colIndex) => filteredData.map(row => row[colIndex]).reverse());
//         const trimmedData = transposedData.filter(col => col.some(cell => cell !== null));
//         const finalData = trimmedData[0].map((_, rowIndex) => trimmedData.map(col => col[rowIndex]).reverse());

//         return finalData;
//     };

//     const spiralData = generateCenteredSpiralData(count);
//     const centerIndex = Math.floor(spiralData.length / 2);

//     return (
//         <div className='miscellaneous-main-container'>
//             <div className="table-ticker">
//                 <div className='miscellaneous-header'>
//                     <h1>Box Theory</h1>
//                 </div>
//                 <div className='flex'>
//                     {/* <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />
//                     <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
//                     <IndicesStrip token="259849" IndiceLabel="IT NIFTY" /> */}
//                     <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} showNIFTYf={true} showBankNiftyF={true}/>
//                 </div>
//             </div>

//             <div className='box-table' style={{ display: 'flex', justifyContent: 'center', padding: '10px', height: 'calc(100vh - 125px)', overflow: 'auto', marginTop: '30px' }}>
//                 <table style={{ borderCollapse: 'collapse', fontSize: '16px', backgroundColor: 'lightblue' }}>
//                     <tbody>
//                         {spiralData.map((row, rowIndex) => (
//                             <tr key={rowIndex}>
//                                 {row.map((cell, cellIndex) => {
//                                     const index = cell ? Math.sqrt(cell) - 1 : null; // Calculate index based on cell value
//                                     const isCenterRow = rowIndex === centerIndex; // Check if cell is in the center row
//                                     const isCenterCol = cellIndex === centerIndex; // Check if cell is in the center column
//                                     const isDiagonal = rowIndex === cellIndex || rowIndex + cellIndex === spiralData.length - 1; // Check if cell is on a diagonal

//                                     // Set background color based on cell's position (diagonal, center row/col, or regular)
//                                     let backgroundColor;
//                                     if (isCenterRow || isCenterCol) {
//                                         backgroundColor = '#f27272'; // Black color for center row/column
//                                     } else {
//                                         backgroundColor = cell
//                                             ? (isDiagonal ? '#f27272' : cell % 9 === 0 ? 'green' : getBlueShade(index, count))
//                                             : 'transparent';
//                                     }

//                                     return (
//                                         <td
//                                             key={cellIndex}
//                                             style={{
//                                                 width: '40px',
//                                                 height: '40px',
//                                                 textAlign: 'center',
//                                                 border: '1px solid #333',
//                                                 backgroundColor: backgroundColor,
//                                                 color: cell ? '#000' : 'transparent', // Black text for better contrast
//                                                 fontWeight: 'bold',
//                                             }}
//                                         >
//                                             {cell || ""}
//                                         </td>
//                                     );
//                                 })}
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// };

// export default CenteredSpiralSquareTable;


// import React, { useState } from 'react';
// import './miscellaneous.css';
// import IndiceStripNew from '../common/IndiceStripNew';

// const CenteredSpiralSquareTable = ({ count = 441 }) => {
//     const [boxType, setBoxType] = useState('box1'); // State to manage dropdown selection

//     const getBlueShade = (index, total) => {
//         const maxLightness = 240; // Maximum lightness for a light blue gradient
//         const lightness = Math.floor(maxLightness - (maxLightness / total) * index);
//         return `rgb(173, 216, ${lightness})`;
//     };

//     const generateCenteredSpiralData = (count) => {
//         const data = [];
//         let x = 0, y = 0; // Start at the center
//         let dx = 0, dy = -1;
//         const positionMap = {};

//         const maxDimension = Math.ceil(Math.sqrt(count)) * 2; // Ensure enough space for a spiral
//         for (let i = 1; i <= count; i++) {
//             // Set value based on the selected box type
//             positionMap[`${x},${y}`] = boxType === 'box1' ? i * i : i;

//             // Change direction when needed in the spiral
//             if (x === y || (x < 0 && x === -y) || (x > 0 && x === 1 - y)) {
//                 const temp = dx;
//                 dx = -dy;
//                 dy = temp;
//             }
//             x += dx;
//             y += dy;
//         }

//         // Convert position map into a 2D array for display
//         const maxRadius = Math.ceil(maxDimension / 2);
//         for (let row = -maxRadius; row <= maxRadius; row++) {
//             const rowData = [];
//             for (let col = -maxRadius; col <= maxRadius; col++) {
//                 rowData.push(positionMap[`${col},${row}`] || null);
//             }
//             data.push(rowData);
//         }

//         // Trim rows and columns with all null values to create a centered spiral
//         const filteredData = data.filter(row => row.some(cell => cell !== null));
//         const transposedData = filteredData[0].map((_, colIndex) => filteredData.map(row => row[colIndex]).reverse());
//         const trimmedData = transposedData.filter(col => col.some(cell => cell !== null));
//         const finalData = trimmedData[0].map((_, rowIndex) => trimmedData.map(col => col[rowIndex]).reverse());

//         return finalData;
//     };

//     const updatedCount = boxType === 'box2' ? 1225 : count; // Update count for "Plain Box Theory"
//     const spiralData = generateCenteredSpiralData(updatedCount);
//     const centerIndex = Math.floor(spiralData.length / 2);

//     return (
//         <div className='miscellaneous-main-container'>
//             <div className="table-ticker">
//                 <div style={{ textAlign: 'center' }}>
//                     <select
//                         id="boxType"
//                         value={boxType}
//                         onChange={(e) => setBoxType(e.target.value)}
//                         style={{ padding: '5px', fontSize: '16px' }}
//                     >
//                         <option value="box1">Square Box Theory</option>
//                         <option value="box2">Plain Box Theory</option>
//                     </select>
//                 </div>
//                 <div className='flex'>
//                     <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} showNIFTYf={true} showBankNiftyF={true} />
//                 </div>
//             </div>

//             <div className='box-table' style={{ display: 'flex', justifyContent: 'center', padding: '10px', height: 'calc(100vh - 125px)', overflow: 'auto', marginTop: '30px' }}>
//                 <table style={{ borderCollapse: 'collapse', fontSize: '16px', backgroundColor: 'lightblue' }}>
//                     <tbody>
//                         {spiralData.map((row, rowIndex) => (
//                             <tr key={rowIndex}>
//                                 {row.map((cell, cellIndex) => {
//                                     const index = cell ? (boxType === 'box1' ? Math.sqrt(cell) - 1 : cell - 1) : null; // Adjust index for box type
//                                     const isCenterRow = rowIndex === centerIndex; // Check if cell is in the center row
//                                     const isCenterCol = cellIndex === centerIndex; // Check if cell is in the center column
//                                     const isDiagonal = rowIndex === cellIndex || rowIndex + cellIndex === spiralData.length - 1; // Check if cell is on a diagonal

//                                     // Set background color based on cell's position
//                                     let backgroundColor;
//                                     if (cell === 1) {
//                                         backgroundColor = '#FFFFFF'; // White color for the box with number 1
//                                     } else if (isCenterRow || isCenterCol) {
//                                         backgroundColor = '#f27272'; // Red for center row/column
//                                     } else {
//                                         backgroundColor = cell
//                                             ? (isDiagonal ? '#0000FF' : cell % 9 === 0 ? 'green' : getBlueShade(index, updatedCount))
//                                             : 'transparent';
//                                     }

//                                     return (
//                                         <td
//                                             key={cellIndex}
//                                             style={{
//                                                 width: '40px',
//                                                 height: '40px',
//                                                 textAlign: 'center',
//                                                 border: '1px solid #333',
//                                                 backgroundColor: backgroundColor,
//                                                 color: cell ? '#000' : 'transparent', // Black text for better contrast
//                                                 fontWeight: 'bold',
//                                             }}
//                                         >
//                                             {cell || ""}
//                                         </td>
//                                     );
//                                 })}
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// };

// export default CenteredSpiralSquareTable;


import React, { useState } from 'react';
import './miscellaneous.css';
import IndiceStripNew from '../common/IndiceStripNew';

const CenteredSpiralSquareTable = ({ count = 441 }) => {
    const [boxType, setBoxType] = useState('box1'); // State to manage dropdown selection

    const getBlueShade = (index, total) => {
        const maxLightness = 240; // Maximum lightness for a light blue gradient
        const lightness = Math.floor(maxLightness - (maxLightness / total) * index);
        return `rgb(173, 216, ${lightness})`;
    };

    const generateCenteredSpiralData = (count) => {
        const data = [];
        let x = 0, y = 0; // Start at the center
        let dx = 0, dy = -1;
        const positionMap = {};

        const maxDimension = Math.ceil(Math.sqrt(count)) * 2; // Ensure enough space for a spiral
        for (let i = 1; i <= count; i++) {
            // Set value based on the selected box type
            positionMap[`${x},${y}`] = boxType === 'box1' ? i * i : i;

            // Change direction when needed in the spiral
            if (x === y || (x < 0 && x === -y) || (x > 0 && x === 1 - y)) {
                const temp = dx;
                dx = -dy;
                dy = temp;
            }
            x += dx;
            y += dy;
        }

        // Convert position map into a 2D array for display
        const maxRadius = Math.ceil(maxDimension / 2);
        for (let row = -maxRadius; row <= maxRadius; row++) {
            const rowData = [];
            for (let col = -maxRadius; col <= maxRadius; col++) {
                rowData.push(positionMap[`${col},${row}`] || null);
            }
            data.push(rowData);
        }

        // Trim rows and columns with all null values to create a centered spiral
        const filteredData = data.filter(row => row.some(cell => cell !== null));
        const transposedData = filteredData[0].map((_, colIndex) => filteredData.map(row => row[colIndex]).reverse());
        const trimmedData = transposedData.filter(col => col.some(cell => cell !== null));
        const finalData = trimmedData[0].map((_, rowIndex) => trimmedData.map(col => col[rowIndex]).reverse());

        return finalData;
    };

    const updatedCount = boxType === 'box2' ? 1225 : count; // Update count for "Plain Box Theory"
    const spiralData = generateCenteredSpiralData(updatedCount);
    const centerIndex = Math.floor(spiralData.length / 2);

    return (
        <div className='miscellaneous-main-container'>
            <div className="table-ticker">
                <div style={{ textAlign: 'center' }}>
                    <select
                        id="boxType"
                        value={boxType}
                        onChange={(e) => setBoxType(e.target.value)}
                        style={{ padding: '5px', fontSize: '16px' }}
                    >
                        <option value="box1">Square Box Theory</option>
                        <option value="box2">Natural Number System</option>
                    </select>
                </div>
                <div className='flex'>
                    <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} />
                </div>
            </div>

            <div className='box-table' style={{ display: 'flex', justifyContent: boxType === 'box2' ? "start" : 'center', padding: '10px', height: 'calc(100vh - 125px)', overflow: 'auto', marginTop: '30px', }}>
                <table style={{ borderCollapse: 'collapse', fontSize: '16px', backgroundColor: 'lightblue' }}>
                    <tbody>
                        {spiralData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => {
                                    const index = cell ? (boxType === 'box1' ? Math.sqrt(cell) - 1 : cell - 1) : null; // Adjust index for box type
                                    const isCenterRow = rowIndex === centerIndex; // Check if cell is in the center row
                                    const isCenterCol = cellIndex === centerIndex; // Check if cell is in the center column
                                    const isDiagonal = rowIndex === cellIndex || rowIndex + cellIndex === spiralData.length - 1; // Check if cell is on a diagonal
                                    let backgroundColor;
                                    if (cell === 1) {
                                        backgroundColor = '#FFFFFF'; // White color for the box with number 1
                                    } else if (isCenterRow || isCenterCol) {
                                        backgroundColor = '#f27272'; // Red for center row/column
                                    } else {
                                        backgroundColor = cell
                                            ? (isDiagonal
                                                ? '#f27272' // Red for diagonals in both theories
                                                : boxType === 'box1' && cell % 9 === 0
                                                    ? 'green' // Green for multiples of 9 in Square Box Theory
                                                    : getBlueShade(index, updatedCount)) // Blue shade for other cells
                                            : 'transparent';
                                    }
                                    return (
                                        <td
                                            key={cellIndex}
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                textAlign: 'center',
                                                border: '1px solid #333',
                                                backgroundColor: backgroundColor,
                                                color: cell ? '#000' : 'transparent', // Black text for better contrast
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {cell || ""}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CenteredSpiralSquareTable;

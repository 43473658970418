import React from 'react';
import './ContactUs.css';
import contactImage from '../../assets/images/Ellipse22.png'; // Update this path to your image

const ContactUs = () => {
  return (
    <div className="contact-container">
      <h2 className="contact-header">Contact Us</h2>
      <div className="contact-center">
        <div className="contact-image-wrapper">
          <img src={contactImage} alt="Contact" className="contact-image" />
        </div>
        <div className="contact-info-wrapper">
          <div className="contact-info left">
            <div className="contact-item">
              <div className="contact-icon">📞</div>
              <div className="contact-text">+91 040-44724242</div>
            </div>
            <div className="contact-item">
              <div className="contact-icon">📞</div>
              <div className="contact-text">+91 9154341087 </div>
            </div>
          </div>
          <div className="contact-info right">
            <div className="contact-item">
              <div className="contact-icon">📧</div>
              <div className="contact-text">info@tradabulls.com</div>
            </div>
            <div className="contact-item">
              <div className="contact-icon">🏢</div>
              <div className="contact-text">
                607, Capital Park, Image Gardens, Madhapur,
                Hyderabad, Telangana 500081
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useEffect, useMemo, useState } from "react";
import IndexMoversSectorsChart from "../common/IndexMoversSectorsChart";
import { IndexMoversSectorsData, NiftyPlusData } from "../apiService/tickerApiService";

function IndexmoversSectors(props) {
    const { indexTime, indice, indexTimeLable } = props
    const [sectorsData, setSectorssData] = useState([])
    const [sectorData, setSectorData] = useState([]);
    const [loading, setLoading] = useState(true);

    const sectorDataComponent = async (i, tf) => {
        let _c = await NiftyPlusData(tf)
        switch (i) {
            case "nifty50":
                const _candlesData = _c.data.filter((e, i) => e.companyData.companySymbol !== "FINNIFTY" && e.companyData.companySymbol !== "NIFTYNEXT50" && e.companyData.companySymbol !== "MIDCAPSELECT")
                const order = ["NIFTY50", "BANKNIFTY", "ITNIFTY", "RELIANCE"];
                _candlesData?.sort((a, b) => {
                    return order.indexOf(a.companyData.companySymbol) - order.indexOf(b.companyData.companySymbol);
                })
                setSectorssData(_candlesData)
                fetchSectorsData(indice, _candlesData[0]?.currentprice, indexTime)
                break
            case "next50":
                const _next50Data = _c.data.filter((e, i) => e.companyData.companySymbol === "NIFTYNEXT50")
                setSectorssData(_next50Data)
                fetchSectorsData(indice, _next50Data[0]?.currentprice, indexTime)
                break
            case "midcapselect":
                const _midcap = _c.data.filter((e, i) => e.companyData.companySymbol === "MIDCAPSELECT")
                setSectorssData(_midcap)
                fetchSectorsData(indice, _midcap[0]?.currentprice, indexTime)
                break
            default:
                setSectorssData([])
                setLoading(false)
                break

        }
    }
    const fetchSectorsData = async (i, cmp, tf) => {
        let _i = i.toUpperCase()
        const sectorsData = await IndexMoversSectorsData(_i, Math.ceil(cmp), tf)
        setSectorData(sectorsData.data)
        setLoading(false)
    }

    useEffect(() => {
        let intervalId;
        if (indice && indexTime) {
            intervalId = setInterval(() => {
                sectorDataComponent(indice, indexTime)
            }, 4000); //important change need to change for 4000
        }

        return () => {
            clearInterval(intervalId)
        }
    }, [indice, indexTime])

    useMemo(() => {
        if (indice && indexTime) {
            sectorDataComponent(indice, indexTime)
        }
    }, [indice, indexTime])

    console.log('sectorsData...', sectorsData, sectorsData.length);

    return (
        <div>
            {sectorsData.length > 0 ?
                <IndexMoversSectorsChart label={indexTimeLable} _filterLabel={indice} cmp={sectorsData[0]?.currentprice} tf={indexTime} stockData={sectorData} loading={loading} /> :
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', fontFamily: 'Futura PT Demi' }}>
                    No Data Avaliable
                </div>
            }
        </div>
    )
}
export default IndexmoversSectors;
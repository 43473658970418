import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
} from "@material-ui/core";
import dayjs from "dayjs";
import { fetchAllHeatMapTrend } from "../apiService/tickerApiService";
import React, { useEffect, useMemo, useState } from "react";
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";

function Trend(props) {
    const { label } = props;
    const [heatmapsTrendsData, setHeatmapsTrendsData] = useState([]);

    const HeatmapsTrends = async () => {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const previousDay = dayjs().subtract(20, "day").format("YYYY-MM-DD");
        const response = await fetchAllHeatMapTrend(currentDate, previousDay,label);

        // Flatten the response data
        const flattenedData = response?.data?.flat() || [];
        setHeatmapsTrendsData(flattenedData);
    };

    useEffect(() => {
        HeatmapsTrends();
    }, [label]);



    // Prepare the data to render in a table format
    const renderTable = useMemo(() => {
        if (!heatmapsTrendsData || heatmapsTrendsData.length === 0) return null;

        // Get unique dates and sort them (ascending)
        const dates = [...new Set(heatmapsTrendsData.map((item) => item.date))].sort(
            (a, b) => new Date(a) - new Date(b)
        );

        // Get unique symbols
        const symbols = [...new Set(heatmapsTrendsData.map((item) => item.symbol))];

        const getArrow = (value) => {
            if (value < 38.2) {
                return <ArrowDownward style={{ color: 'red' }} />;
            } else if (value >= 61.8) {
                return <ArrowUpward style={{ color: 'green' }} />;
            } else {
                return <Height style={{ rotate: "90deg", color: '#DAA520' }} />;
            }
        };

        const formatDate = (date) => {
            const options = { day: 'numeric', month: 'short' }; // 'short' for abbreviated month name
            const formattedDate = new Date(date).toLocaleDateString('en-US', options);
            return formattedDate.replace(' ', '-'); // Replaces the space between month and day with a hyphen
        };
        return (
            <Box className=''>
                <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 210px)`, padding: '0px 10px' }}>
                    <Table className="table" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            <TableCell className="headerCell p-0">
                                <Grid className="flex justify-center top-container">
                                    <Typography align='center font-black' style={{ color: "#ffffff", display: 'flex', alignItems: 'center' }}>Symbol</Typography>
                                </Grid>
                            </TableCell>
                            <TableCell className="headerCell p-0">
                                <Grid className="flex justify-center top-container">
                                    <Typography align='center font-black' style={{ color: "#ffffff", display: 'flex', alignItems: 'center' }}>Trend</Typography>
                                </Grid>
                            </TableCell>
                                {dates.map((date) => (
                                    <TableCell key={date} className="headerCell p-0">
                                        <Grid container direction="column">
                                            <Grid className="flex top-container justify-around" style={{ color: "#ffffff", display: 'flex', alignItems: 'center',fontSize:'13px' }}>
                                                <Grid>
                                                    <Typography align='center' className="timeDur">
                                                        <span className='mx-1 ' style={{fontSize:'13px'}}>{formatDate(date)}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {symbols.map((symbol) => {
                                const row = heatmapsTrendsData.filter(
                                    (item) => item.symbol === symbol
                                );

                                return (
                                    <TableRow key={symbol} className='tableCell'>
                                        <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: "#154A72" }} align='center'>{symbol}</Typography>
                                            </TableCell>
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: "#154A72" }} align='center'>ST</Typography>
                                                <Typography style={{ color: "#154A72" }} align='center'>MT</Typography>
                                                <Typography style={{ color: "#154A72" }} align='center'>LT</Typography>
                                            </TableCell>
                                        {dates.map((date) => {
                                            const dailyData = row.find((item) => item.date === date);
                                            return (
                                                <TableCell key={date} className='tableCell text-center font-black p-1'>
                                                   <Typography align="center"> {getArrow((dailyData?.dailyPercentage + dailyData?.weeklyPercentage + dailyData?.monthlyPercentage)/3)}   </Typography>
                                                   <Typography align="center"> {getArrow((dailyData?.monthlyPercentage + dailyData?.quaterlyPercentage + dailyData?.halfyearlyPercentage)/3)}   </Typography>
                                                   <Typography align="center"> {getArrow((dailyData?.quaterlyPercentage + dailyData?.halfyearlyPercentage + dailyData?.yearlyPercentage)/3)}   </Typography>
                                                    
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box >
        );
    }, [heatmapsTrendsData]);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>{renderTable}</Grid>
        </Grid>
    );
}

export default Trend;

import { Box, Button, Dialog, Grid, IconButton, Snackbar, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AppLogo } from "../icons/AppIcon";
import GenerateFormFields from "../common/GenerateFormFields";
import { useCustomForm } from "../common/useCustomForm";
import { VisibilityOffOutlined, VisibilityOutlined, Close, ThumbUp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import validateSignUp from "./SignUpValidator";
import { Alert } from "@material-ui/lab";
import { EyeSvgIcon, Hide, MailIcon, PasswordIcon, PhoneSvgIcon, UserSvgIcon } from "../../assets/svgIcons/svgIcons";
import Captcha from "../captcha/Captcha";
import '../../App.css'
import { signUpApi } from "../apiService/apiService";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../common/Loader";
const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    columnGap: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  mainHeading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#154A72",
    marginTop: "10px"
  },
  createAcc: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
  }
}))

const initialFormValues = { firstName: "", lastName: "", email: "", phoneNumber: "", password: "", reEnterPassword: "", captcha: "" }
const Signup = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const [showPasswordTxt, setShowPasswordTxt] = useState("password");
  const [showRePasswordTxt, setShowRePasswordTxt] = useState("password");
  const [fieldState, setFieldState] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showStatusText, setShowStatusText] = useState('');

  const notify = (sev, str) => toast[sev ? sev : 'info'](str);

  const [renderCaptcha, SetRenderCaptcha] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      SetRenderCaptcha(true)
    }, 300);
  }, [])
  const onSignUp = async () => {
    const reqPayload = {};
    let a = false;
    for (const key in userForm) {
      const element = userForm[key];
      reqPayload[key] = element.toString();
      // reqPayload.append(key, element.toString());
    }
    if (reqPayload.password === reqPayload.reEnterPassword) {
      a = true
    }
    if (a && reqPayload) {
      if (reqPayload.reEnterPassword) {
        delete reqPayload.reEnterPassword;
      }
      setLoading(true)
      const signUpStatus = await signUpApi(reqPayload)
      if (signUpStatus?.data?.success) {
        setLoading(false)
        onDisappearDilog()
        props.setShowSignUp(false)
        setShowStatus(true)
        setShowStatusText(`${signUpStatus?.data?.message}`)
        // notify("success", `${signUpStatus?.data?.message}`)
      } else {
        setLoading(false)
        setShowStatus(false)
        notify("warning", `${signUpStatus?.data?.message}`)
      }
    }
    // else if(!a){
    //   setErrors({reEnterPassword : {message : ""}})
    // }
  };
  const { errors, handleChange, handleSubmit, values: userForm, setErrors } = useCustomForm(
    fieldState,
    onSignUp,
    validateSignUp);
  const formGenSignUpMetadata = [
    {
      componentNm: "textfield",
      labelText: "First Name",
      name: "firstName",
      placeholder: "First Name ",
      classes: {
        root: "mt-3",
      },
      isRequired: true,
      errors: errors,
      className: "w-full h-8",
      onChange: handleChange,
      startAdornment: (<UserSvgIcon />)
    },
    {
      componentNm: "textfield",
      labelText: "Last Name",
      name: "lastName",
      placeholder: "Last Name ",
      classes: {
        root: "mt-3",
      },
      isRequired: true,
      errors: errors,
      className: "w-full h-8",
      onChange: handleChange,
      startAdornment: (<UserSvgIcon />)
    },
    {
      componentNm: "textfield",
      labelText: "E-mail",
      name: "email",
      placeholder: "Enter Your email",
      classes: {
        root: "mt-3",
      },
      isRequired: true,
      errors: errors,
      className: "w-full h-8 pl-3",
      onChange: handleChange,
      startAdornment: (<MailIcon />)
    },
    {
      componentNm: "textfield",
      labelText: "Phone number",
      name: "phoneNumber",
      placeholder: "Enter Your phone number",
      classes: {
        root: "mt-3",
      },
      isRequired: true,
      errors: errors,
      className: "w-full h-8",
      onChange: handleChange,
      startAdornment: (<PhoneSvgIcon />)
    },
    {
      componentNm: "textfield",
      labelText: "Password",
      name: "password",
      classes: {
        root: "mt-3",
      },
      isRequired: true,
      type: showPasswordTxt,
      placeholder: "Enter Your password",
      errors: errors,
      className: "w-full h-8",
      onChange: handleChange,
      onKeyPress: (e) => {
        e.persist();
        e.key === "Enter" && handleSubmit(e);
      },
      startAdornment: (<PasswordIcon />),
      endAdornment: (
        <IconButton
          size="small"
          onClick={() =>
            setShowPasswordTxt(
              showPasswordTxt === "password" ? "text" : "password"
            )
          }
          color="primary"
        >
          {showPasswordTxt === "password" ? (
            <Hide />
          ) : (
            <EyeSvgIcon />

          )}
        </IconButton>
      ),
    },
    {
      componentNm: "textfield",
      labelText: "Confirm Password",
      name: "reEnterPassword",
      classes: {
        root: "mt-3",
      },
      isRequired: true,
      type: showRePasswordTxt,
      placeholder: "Re-Enter Your password",
      errors: errors,
      className: "w-full h-8",
      onChange: handleChange,
      onKeyPress: (e) => {
        e.persist();
        e.key === "Enter" && handleSubmit(e);
      },
      startAdornment: (<PasswordIcon />),
      endAdornment: (
        <IconButton
          size="small"
          onClick={() =>
            setShowRePasswordTxt(
              showRePasswordTxt === "password" ? "text" : "password"
            )
          }
          color="primary"
        >
          {showRePasswordTxt === "password" ? (
            <Hide />
          ) : (
            <EyeSvgIcon />
          )}
        </IconButton>
      ),
    },
  ];
  const onDisappearDilog = () => {
    setFieldState(initialFormValues)
    setErrors({})
    setShowPasswordTxt('password')
    setShowRePasswordTxt("password")
  }

  const onCloseDialog = () => {
    onDisappearDilog()
    props.setShowSignUp(false)
  }

  const onClosePopup = () => {
    setShowStatus(false)
  }

  return (
    <>
      <Dialog open={props.showSignUp} onClose={onCloseDialog} className="signup-dialog">
        {loading && <Loader />}
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          className='close-icon'
          style={{ position: 'sticky', justifyContent: 'end', right: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <Close />
        </IconButton>
        <Grid container className="h-center md: justify-center" style={{ fontFamily: 'Futura PT Book' }}>
          <Grid item className="h-full px-4">
            <Grid className=" flex justify-center " md={12}>
              <AppLogo height="auto" />
            </Grid>
            <Typography className={classes.mainHeading} align="center" variant="h4">
              Welcome to Tradabulls!
            </Typography>
            <Typography className={classes.createAcc} align="center" variant="h5">
              Create an account
            </Typography>
            <Grid className={classes.formContainer}>
              <GenerateFormFields argsMeta={formGenSignUpMetadata} />
            </Grid>
            <Grid style={{display:'flex',alignItems:'center',gap:'2%',marginTop:'5px'}}>
              <Grid style={{width:'65%',padding:'3px',borderRadius:'4px', fontSize:'12px'}}>
                <p>
                 NOTE : Password should have min 8 characters with Capital case,Numerics and Special characters"
                </p>
              </Grid>
              <Grid style={{ display: 'grid', width: '50%' }}>
                {/* {renderCaptcha ? <Captcha
              callBack={handleSubmit}
              buttonLabel='Create Account'
              errors={errors}
              userForm={userForm}
            /> : <span>loading.....</span>} */}
                <Captcha
                  handleSubmit={handleSubmit}
                  errors={errors}
                  userForm={userForm}
                />
              </Grid>
            </Grid>
            <Grid className="login-button mt-3">
              <Button
                size={"small"}

                // color="primary"
                // variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
            <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', fontSize: '12px ', fontFamily: 'Futura PT Book' }} className="mt-2">
              Already have an account?
              <span onClick={() => {
                onDisappearDilog()
                props.changeToLogin()
              }}
                color="primary"
                className="underline ml-2"
                style={{ cursor: "pointer", fontSize: "15px", fontWeight: '600', fontFamily: 'Futura PT Book' }}
              >
                Log in
              </span>
            </Typography>
          </Grid>
        </Grid>
        <ToastContainer type="warning" position="top-right" draggable={false} pauseOnHover={false} />
      </Dialog>

      <Dialog open={showStatus} onClose={onClosePopup} style={{ borderRadius: '10px' }}>
        <IconButton
          aria-label="close"
          onClick={onClosePopup}
          className='close-icon'
          style={{ position: 'sticky', justifyContent: 'end', right: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <Close />
        </IconButton>
        <Box style={{ padding: '12px' }}>
          <Typography style={{ fontFamily: 'Futura PT Book', color: '#282828', fontWeight: 'bold', fontSize: '20px' }}>
            <ThumbUp />
            {showStatusText}</Typography>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button onClick={() => setShowStatus(false)} className={props.fromHome ? "btn-get-started" : ''}
              style={{ backgroundColor: "#20903d", color: "#ffffff", borderRadius: "7px" }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Signup;

// import React, { useMemo, useState } from "react";
// import { fetchAllTimeHigh } from "../apiService/tickerApiService";
// import { Grid, Typography } from "@material-ui/core";

// const styles = {
//     container: {
//         maxWidth: "600px",
//         margin: "20px auto",
//         padding: "10px",
//         border: "1px solid #ccc",
//         borderRadius: "8px",
//         boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//         backgroundColor: "#f9f9f9",
//     },
//     heading: {
//         textAlign: "center",
//         fontSize: "1rem",
//         color: "#333",
//         marginBottom: "15px",
//         fontWeight: "bold",
//     },
//     table: {
//         width: "100%",
//         borderCollapse: "collapse",
//     },
//     th: {
//         backgroundColor: "#add8e6",
//         color: "#333",
//         fontWeight: "bold",
//         padding: "10px",
//         border: "1px solid #ddd",
//         textTransform: "uppercase",
//         fontSize: "12px",
//         textAlign: "center",
//     },
//     td: {
//         padding: "10px",
//         textAlign: "center",
//         border: "1px solid #ddd",
//         color: "#555",
//     },
//     rowEven: {
//         backgroundColor: "#f4f4f4",
//     },
//     rowOdd: {
//         backgroundColor: "#ffffff",
//     },
// };

// function AllTimeHigh(props) {
//     const { companySymbol } = props;
//     const [values, setValues] = useState([]); // Array of value-key pairs
//     const [bestValues, setBestValues] = useState([]); // Array of best value-key pairs

//     const fetchAllTimeHighs = async () => {
//         try {
//             const _allTimeHighs = await fetchAllTimeHigh(companySymbol);
//             const data = _allTimeHighs?.data; // Response from the API

//             // Extract values keys
//             const valueKeys = Object.keys(data).filter((key) => key.startsWith("value"));
//             const valueList = valueKeys.map((key) => ({
//                 key,
//                 value: data[key],
//             }));

//             // Extract best values keys
//             const bestValueKeys = Object.keys(data.allTimeHighLowBestValue || {}).filter((key) =>
//                 key.startsWith("bestValue")
//             );
//             const bestValueList = bestValueKeys.map((key) => ({
//                 key,
//                 value: data.allTimeHighLowBestValue[key],
//             }));

//             setValues(valueList);
//             setBestValues(bestValueList);
//         } catch (error) {
//             setValues([]);
//             setBestValues([]);
//         }
//     };

//     useMemo(() => {
//         fetchAllTimeHighs();
//     }, []);

//     return (
//         <Grid className="w-4/12border-timeframe plusplus-border p-0 bg-white">
//             <Grid className="header-timeframe">
//                 <Typography
//                     align="center"
//                     className="font-semibold font-futura"
//                     style={{ fontSize: "16px" }}
//                 >
//                     All Time High
//                 </Typography>
//             </Grid>
//             <Grid>
//                 <table style={styles.table}>
//                     <thead>
//                         <tr>
//                             <th style={styles.th}>Values</th>
//                             <th style={styles.th}>Best Values</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {values.map((value, index) => (
//                             <tr key={index} style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}>
//                                 {/* <td>{`${value.key}: ${value.value}`}</td>
//                 <td>
//                   {bestValues[index]
//                     ? `${bestValues[index].key}: ${bestValues[index].value}`
//                     : ""}
//                 </td> */}
//                                 <td style={styles.td}>{(value.value).toFixed(2)}</td>
//                                 <td style={styles.td}>{bestValues[index]
//                                     ? `${(bestValues[index].value).toFixed(2)}`
//                                     : ""}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </Grid>
//         </Grid>
//     );
// }

// export default AllTimeHigh;


import React, { useMemo, useState } from "react";
import { fetchAllTimeHigh } from "../apiService/tickerApiService";
import { Grid, Typography } from "@material-ui/core";

const styles = {
    container: {
        maxWidth: "600px",
        margin: "20px auto",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
    },
    heading: {
        textAlign: "center",
        fontSize: "1rem",
        color: "#333",
        marginBottom: "15px",
        fontWeight: "bold",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    th: {
        backgroundColor: "#add8e6",
        color: "#333",
        fontWeight: "bold",
        padding: "10px",
        border: "1px solid #ddd",
        textTransform: "uppercase",
        fontSize: "12px",
        textAlign: "center",
    },
    td: {
        padding: "10px",
        textAlign: "center",
        border: "1px solid #ddd",
        color: "#555",
    },
    rowEven: {
        backgroundColor: "#f4f4f4",
    },
    rowOdd: {
        backgroundColor: "#ffffff",
    },
};

function AllTimeHigh(props) {
    const { companySymbol, cmp } = props;
    const [combinedValues, setCombinedValues] = useState([]); // Combined sorted list
    const [apiData, setApiData] = useState([]); 
    const fetchAndCombineAllTimeHighs = async () => {
        try {
            const _allTimeHighs = await fetchAllTimeHigh(companySymbol);
            const data = _allTimeHighs?.data;
            setApiData(data)
            // Extract "values" and exclude "value20"
            const valueKeys = Object.keys(data)
                .filter((key) => key.startsWith("value") && key !== "value20"); // Exclude "value20"
    
            const valueList = valueKeys.map((key) => ({
                key,
                value: data[key],
            }));
    
            // Extract "best values"
            const bestValueKeys = Object.keys(data.allTimeHighLowBestValue || {}).filter((key) =>
                key.startsWith("bestValue")
            );
            let bestValueList = bestValueKeys.map((key) => ({
                key,
                value: data.allTimeHighLowBestValue[key],
            }));
    
            // Filter out duplicates where bestValue is equal to any value
            const valueSet = new Set(valueList.map((item) => item.value)); // Use a Set for faster lookup
            bestValueList = bestValueList.filter((bestItem) => !valueSet.has(bestItem.value));
    
            // Combine and sort the lists
            const combinedList = [...valueList, ...bestValueList]
                .filter((item) => item.value !== 0) // Exclude items where value is 0
                .sort((a, b) => b.value - a.value);
    
            setCombinedValues(combinedList);
        } catch (error) {
            console.error("Error fetching data:", error);
            setCombinedValues([]);
        }
    };
    console.log('combinedValues',combinedValues);
    
    // const fetchAndCombineAllTimeHighs = async () => {
    //     try {
    //         const _allTimeHighs = await fetchAllTimeHigh(companySymbol);
    //         const data = _allTimeHighs?.data;

    //         // Extract "values"
    //         const valueKeys = Object.keys(data).filter((key) => key.startsWith("value"));
    //         const valueList = valueKeys.map((key) => ({
    //             key,
    //             value: data[key],
    //         }));

    //         // Extract "best values"
    //         const bestValueKeys = Object.keys(data.allTimeHighLowBestValue || {}).filter((key) =>
    //             key.startsWith("bestValue")
    //         );
    //         const bestValueList = bestValueKeys.map((key) => ({
    //             key,
    //             value: data.allTimeHighLowBestValue[key],
    //         }));

    //         // Combine and sort the lists
    //         const combinedList = [...valueList, ...bestValueList].sort((a, b) => b.value - a.value);

    //         setCombinedValues(combinedList);
    //     } catch (error) {
    //         setCombinedValues([]);
    //     }
    // };
    const GetKeyNames = (k) => {
        switch (k) {
            case 'value1': return "5%"
            case 'value2': return "10%"
            case 'value3': return "15%"
            case 'value4': return "20%"
            case 'value5': return "25%"
            case 'value6': return "30%"
            case 'value7': return "35%"
            case 'value8': return "40%"
            case 'value9': return "45%"
            case 'value10': return "50%"
            case 'value11': return "55%"
            case 'value12': return "60%"
            case 'value13': return "65%"
            case 'value14': return "70%"
            case 'value15': return "75%"
            case 'value16': return "80%"
            case 'value17': return "85%"
            case 'value18': return "90%"
            case 'value19': return "95%"
            case 'value20': return "100%"
            case 'bestValue1': return "5%"
            case 'bestValue2': return "8%"
            case 'bestValue3': return "13%"
            case 'bestValue4': return "21%"
            case 'bestValue5': return "34%"
            case 'bestValue6': return "55%"
            case 'bestValue7': return "89%"
        }
    }

    useMemo(() => {
        fetchAndCombineAllTimeHighs();
    }, []);

   

    return (
        // <Grid className="w-4/12 border-timeframe plusplus-border p-0 bg-white">
        //     <Grid className="header-timeframe">
        //         <Typography
        //             align="center"
        //             className="font-semibold font-futura"
        //             style={{ fontSize: "16px" }}
        //         >
        //             Away From ATH
        //         </Typography>
        //     </Grid>
        //     <Grid>
        //         <table style={styles.table}>
        //             <thead>
        //                 <tr>
        //                     <th style={styles.th}>Breakup %</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 {combinedValues.map((item, index) => (
        //                     <tr key={index} style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}>
        //                         <td style={styles.td}><span style={{ color: cmp >= item.value.toFixed(2) ? 'green' : 'red' }}>{item.value.toFixed(2)} (-{GetKeyNames(item.key)})</span></td>
        //                     </tr>
        //                 ))}
        //             </tbody>
        //         </table>
        //     </Grid>
        // </Grid >

        <Grid className="w-3/12 border-timeframe plusplus-border p-0 bg-white">
            <Grid className="header-timeframe">
                <Typography
                    align="center"
                    className="font-semibold font-futura"
                    style={{ fontSize: "16px" }}
                >
                    Away From ATH ({apiData?.allTimeHigh})

                </Typography>
            </Grid>
            <Grid>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>CMP : {cmp} {cmp && apiData?.allTimeHigh?`(${(cmp-apiData?.allTimeHigh).toFixed(1)})`:'0'}{cmp && apiData?.allTimeHigh ? `(${(((cmp / apiData.allTimeHigh) * 100)-100).toFixed(2)}%)` : ""}</th>
                            {/* <th style={styles.th}>{cmp && apiData?.allTimeHigh?`${(cmp-apiData?.allTimeHigh)}`:'0'}{cmp && apiData?.allTimeHigh ? `${(((cmp / apiData.allTimeHigh) * 100)-100).toFixed(2)}%` : ""}</th> */}
                        </tr>
                        <tr>
                            <th style={styles.th}>Breakup (- %)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            let firstGreenFound = false; // Flag to track the first green value
                            return combinedValues.map((item, index) => {
                                const isGreen = cmp >= item.value.toFixed(2); // Determine green condition
                                const isFirstGreen = isGreen && !firstGreenFound;
                                if (isFirstGreen) {
                                    firstGreenFound = true; // Set flag after the first green
                                }
                                return (
                                    <tr
                                        key={index}
                                        style={{...(index % 2 === 0 ? styles.rowEven : styles.rowOdd),}}
                                    >
                                        <td style={styles.td}>
                                            <span style={{  border: isFirstGreen ? "2px solid green" : "none", padding: isFirstGreen ?'3px 5px':'0px', borderRadius:'5px', fontWeight:'bold',color: isGreen ? "green" : "red",fontSize:'10px'}}>
                                                {item.value.toFixed(1)} (-{GetKeyNames(item.key)})
                                            </span>
                                        </td>
                                    </tr>
                                );
                            });
                        })()}
                    </tbody>
                </table>
            </Grid>
        </Grid>

    );
}

export default AllTimeHigh;

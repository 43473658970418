import React, { useEffect, useMemo, useState } from "react";
import {  Popover, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { fetchAllCompaniesTrend } from "../apiService/tickerApiService";
import "./ButtonWithPopup.css";
import nifty50 from '../../assets/images/IndicesLogos/nifty50.png'

const ButtonWithPopup = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [companiesData, setCompaniesData] = useState({});

    const companiesTrend = async () => {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const previousDay = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        let _companies = await fetchAllCompaniesTrend(currentDate, previousDay);
        setCompaniesData(_companies?.data);
    };

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        let intervalId = setInterval(() => {
            companiesTrend();
        }, 1500000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useMemo(() => {
        companiesTrend();
    }, []);

    const isOpen = Boolean(anchorEl);

    return (
        <div style={{width:'26px',margin:'auto'}}>
            <div  className="blinking-button" onClick={handleButtonClick}>
            <img src={nifty50}  style={{width:'25px', borderRadius:'25px'}} />
            </div>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Box sx={{ p: 2, maxWidth: 600 }} style={{ height: '320px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <Typography  gutterBottom  style={{fontFamily:'Futura PT Heavy', }}>
                        Nifty 50 Short Term Trend Changing Counters
                    </Typography>

                    {/* Positive Companies */}
                    <Table
                        size="small"
                        sx={{
                            border: "2px solid green",
                            borderRadius: "8px",
                            mb: 2,
                        }}
                    >
                        <TableHead  style={{background:"#deffbb"}}>
                            <TableRow>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'green'}} >Symbol</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'green'}} align="right">CMP</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'green'}} align="right">Open</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'green'}} align="right">High</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'green'}} align="right">Low</TableCell>
                            </TableRow>
                        </TableHead>
                        {companiesData.positive ? companiesData.positive.map((company) => (
                            <TableBody key={company.symbol}>
                                <TableRow>
                                    <TableCell style={{fontFamily:'Futura PT Demi',color:'#fff',backgroundColor:'green',borderRadius:'7px',marginLeft:'2px'}}>{company.symbol}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.currentMarketPrice}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.open}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.high}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.low}</TableCell>
                                </TableRow>
                            </TableBody>
                        )) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No data
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>

                    {/* Negative Companies */}
                    <Table
                        size="small"
                        sx={{
                            border: "2px solid red",
                            borderRadius: "8px",
                        }}
                    >
                        <TableHead style={{background:"#ffbbbb"}}>
                            <TableRow>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'red'}}>Symbol</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'red'}}align="right">CMP</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'red'}}align="right">Open</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'red'}}align="right">High</TableCell>
                                <TableCell style={{fontFamily:'Futura PT Heavy', fontSize:'16px',color:'red'}}align="right">Low</TableCell>
                            </TableRow>
                        </TableHead>
                        {companiesData.negative ? companiesData.negative.map((company) => (
                            <TableBody key={company.symbol}>
                                <TableRow>
                                    <TableCell  style={{fontFamily:'Futura PT Demi', color:'#fff',backgroundColor:'red',borderRadius:'7px',marginLeft:'2px'}}>{company.symbol}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.currentMarketPrice}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.open}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.high}</TableCell>
                                    <TableCell style={{fontFamily:'Futura PT Book', fontWeight:'bold'}} align="right">{company.low}</TableCell>
                                </TableRow>
                            </TableBody>
                        )) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No data
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </Box>
            </Popover>
        </div>
    );
};

export default ButtonWithPopup;

import appLogo from '../../assets/images/app_logo.jpg';
import ShieldLogo from '../../assets/images/Shield.svg';
import UserLogo from '../../assets/images/user.svg';
import logo from '../../assets/images/newtradabullslogo.png';
import logo1809 from '../../assets/images/NewLogo.png';
import login_image from '../../assets/images/login_image.png';
import hyd_icon from '../../assets/images/hyd_icon.png';
import faq_image from '../../assets/images/faq_image.png';
import comming from "../../assets/images/comming.png"

export default function AppIcon({ width = 96, height = 'auto', classes = '' }) {
  return (
    <div className={classes} style={{ objectFit: 'cover', width, height }}>
      <img src={appLogo} alt="med 360 icon" />
    </div>
  );
}

export function Comming({classes = '' }) {
  return (
    <div className={classes} style={{ objectFit: 'cover' }}>
      <img src={comming} alt="med 360 icon" />
    </div>
  );
}

export function FAQimage( classes = '') {
  return (
    <div className={classes} style={{ objectFit: 'cover', display: 'flex', justifyContent: 'center' }}>
      <img src={faq_image} alt="med 360 icon" />
    </div>
  )
}

export function HydIcon({ width = 162, height = 50, classes = '' }) {
  return (
    <div className={classes} style={{ objectFit: 'cover', display: 'flex', justifyContent: 'center', width, height }}>
      <img src={hyd_icon} alt="med 360 icon" />
    </div>
  );
}

export function LoginImage({ classes = '' }) {
  return (
    <div className={classes} style={{ objectFit: 'cover', display: 'flex', justifyContent: 'center' }}>
      <img src={login_image} alt="med 360 icon" />
    </div>
  );
}

export function ShieldIcon({ height = 'auto', classes = "" }) {
  return <div className={classes} style={{ objectFit: 'cover', height }}>
    <img src={ShieldLogo} alt="sheid-side-logo" />
  </div>
}

export function UserIcon({ height = 'auto', classes = "" }) {
  return <div className={classes} style={{ objectFit: 'cover', height }}>
    <img src={UserLogo} alt="user-logo" />
  </div>
}

export function ProfileViewsIcon({ height = 'auto', classes = "" }) {
  return <div className={classes} style={{ objectFit: 'cover', height }}>
    <img src={UserLogo} alt="user-logo" />
  </div>
}

export function AppLogo({ width = 180, height = 'auto', classes = '' }) {
  return (
    <div className={classes} style={{ objectFit: 'cover', width, height, pointerEvents: 'none', userSelect: 'none' }}>
      <img src={logo1809} alt="med 360 icon" />
    </div>
  );
}



import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons';

const GaugeChart = (props) => {
    // const { value } = props
    const { value, high, low, open, close, pre_percentage, cmp, previousclose, prev_range, curr_range } = props
    const chartRef = useRef(null);
    const [high_v, setHigh_v] = useState(0);
    const [low_v, setLow_v] = useState(0);
    const h_l_diff = high_v - low_v
    const l_prec = ((close - low_v) / h_l_diff) * 100

    useEffect(() => {
        let h = Math.max(high, open, close)
        let l = Math.min(low, open, close)
        setHigh_v(h)
        setLow_v(l)
        const dom = chartRef.current;
        const myChart = echarts.init(dom, null, {
            renderer: 'canvas',
            useDirtyRect: false
        });

        const option = {
            series: [
                {
                    type: 'gauge',
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [
                                [0.382, '#da2424'],  // Red for 0% - 38.2%
                                [0.618, '#ffb200e3'], // Orange for 38.2% - 61.8%
                                [1, '#44db67'],      // Green for 61.8% - 100%
                            ],
                        }
                    },
                    pointer: {
                        length: '90%',
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        distance: 0,
                        length: 0,
                        lineStyle: {
                            color: '#fff',
                            width: 2
                        }
                    },
                    splitLine: {
                        distance: 0,
                        length: 0,
                        lineStyle: {
                            color: '#fff',
                            width: 4
                        }
                    },
                    axisLabel: {
                        color: 'inherit',
                        distance: 0,
                        fontSize: 0
                    },
                    detail: {
                        valueAnimation: true,
                        formatter: `${props?.value ? props?.value : 0}%`,
                        color: 'inherit',
                        fontSize: 11,
                        offsetCenter: [0, '50%']
                    },
                    data: [
                        {
                            value: props?.value ? props?.value : 0
                        }
                    ]
                }
            ]
        };

        if (option && typeof option === 'object') {
            myChart.setOption(option);
        }

        const interval = setInterval(() => {
            myChart.setOption({
                series: [
                    {
                        data: [
                            {
                                value: props?.value ? props?.value : 0
                            }
                        ]
                    }
                ]
            });
        }, 2000);

        window.addEventListener('resize', myChart.resize);

        // Cleanup on component unmount
        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', myChart.resize);
            myChart.dispose();
        };
    }, [value, high, low]);

    const getHold = (v) => {
        if (v <= 38.2) return "Bearish"
        // else if (v > 20 && v <= 40) return "Bearish+"
        else if (v > 61.8 ) return "Bullish"
        else {
            return "Neutral"
        } 
    }

    const getColor = (v) => {  
        if (v <= 38.2) return "#da2424"
        else if (v >= 61.8 ) return "#339249"
        else { return "#ffa100e3"}
        
    }
const getMeanArrow= (arr)=>{
    let v = (Number(arr[0]) + Number(arr[1]) + Number(arr[2])) / 3
    if (v <= 38.2) {
             return (
                 <>
                     <ArrowDownward style={{ fontSize: "18px", color: "#DA2424" }} />
 
                 </>
             );
         }
        
         else if (v >= 61.8) {
             return (
                 <>
                     <ArrowUpward color="secondary" style={{ fontSize: "18px", color: "#339249" }} />
                 </>
             )
         }
         else {
             return (
                 <>
 
                     <Height style={{ rotate: "90deg", color: " #b5812a" }} />
                 </>
             )
         }
}
    const getShortAndLong = (l_prec, prev_range, previousClose, open) => { 
        // debugger
        let percentage = (prev_range + (curr_range ? curr_range : l_prec)) / 2
        if ((percentage <= 38.2 && ((cmp < previousClose) || (cmp < open)))) {
            return 'No Long';
        }
        else if ((percentage >= 61.8 && ((cmp > previousClose) || (cmp > open)))) {
            return 'No Short'
        }
        else {
            return 'No Action';
        }

    }
    const getShortAndLongColor = (l_prec, prev_range, previousClose, open) => {
        let percentage = (prev_range + (curr_range ? curr_range : l_prec)) / 2
        if ((percentage <= 38.2 && ((cmp < previousClose) || (cmp < open)))) return '#da2424';
        else if ((percentage >= 61.8 && ((cmp > previousClose) || (cmp > open)))) return '#339249';
        else {
            return '#ffa100e3';
        }
    }


    return (
        <div style={{ position: 'relative', }}>
            <div ref={chartRef} className='-mt-4 -mb-10' style={{ width: '100%', height: '180px' }}></div>
            <Typography align='center' style={{ color: getColor(value), marginTop: "-3.4rem", fontWeight: "bold", fontSize: "12px" }}>{getHold(value)}</Typography>
            <Typography align='center' style={{ color: getShortAndLongColor(curr_range, prev_range, previousclose, open), marginTop: "-0.3rem", fontWeight: "bold", fontSize: "12px" }}>{getShortAndLong(curr_range, prev_range, previousclose, open)}</Typography>
            {/* <div className='gaugechart-observations'>
                {getMeanArrow(value)}
            </div> */}
        </div>
    );
};

export default GaugeChart;

import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Grid, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons';

const GaugeChartNV = (props) => {
    const { value, tf, viewPercentages, mean, high, low, open, close, prev_range, traderLow, traderOpen, tradaClose, tradaHigh, tradaRange, cmp, previousclose } = props
    const [tfs, setTfs] = useState(props?.tf.length > 0 ? tf : [])
    const [precentages, setPrecentages] = useState(props?.viewPercentages.length > 0 ? viewPercentages : [])
    const chartRef = useRef(null);
    const [dailyHigh_v, setDailyHigh_v] = useState(0);
    const [dailyLow_v, setDailyLow_v] = useState(0);
    const [WeekelyHigh_v, setWeekelyHigh_v] = useState(0);
    const [WeekelyLow_v, setWeekelyLow_v] = useState(0);
    const [monthlyHigh_v, setMontlyHigh_v] = useState(0);
    const [monthlyLow_v, setMontlyLow_v] = useState(0);

    const daily_h_l_diff = dailyHigh_v - dailyLow_v
    const daily_prec = ((tradaClose?.[0] - dailyLow_v) / daily_h_l_diff) * 100

    const weekely_h_l_diff = WeekelyHigh_v - WeekelyLow_v
    const weekely_prec = ((tradaClose?.[1] - WeekelyLow_v) / weekely_h_l_diff) * 100

    const monthly_h_l_diff = monthlyHigh_v - monthlyLow_v
    const monthly_prec = ((tradaClose?.[2] - monthlyLow_v) / monthly_h_l_diff) * 100

    useEffect(() => {
        let dailyMax = Math.max(tradaHigh?.[0], traderOpen?.[0], tradaClose?.[0]);
        let dailyMin = Math.min(traderLow?.[0], traderOpen?.[0], tradaClose?.[0]);
        setDailyHigh_v(dailyMax)
        setDailyLow_v(dailyMin)

        let weeklyMax = Math.max(tradaHigh?.[1], traderOpen?.[1], tradaClose?.[1]);
        let weeklyMin = Math.min(traderLow?.[1], traderOpen?.[1], tradaClose?.[1]);
        setWeekelyHigh_v(weeklyMax)
        setWeekelyLow_v(weeklyMin)

        let monthlyMax = Math.max(tradaHigh?.[2], traderOpen?.[2], tradaClose?.[2]);
        let monthlyMin = Math.min(traderLow?.[2], traderOpen?.[2], tradaClose?.[2]);
        setMontlyHigh_v(monthlyMax)
        setMontlyLow_v(monthlyMin)

        const calculateGaugeData = () => {
            const meanValue = getMean(viewPercentages);

            return [
                {
                    value: viewPercentages[0],
                    name: `${tfs[0]}`,
                    title: {
                        offsetCenter: ['-110%', '90%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: "#000000"
                    },
                    detail: {
                        offsetCenter: ['-110%', '110%'],
                        fontSize: "11px",
                        formatter: () => `${viewPercentages?.length > 0 ? viewPercentages[0] : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '50%',
                        width: 7,
                        itemStyle: {
                            color: 'auto',
                            borderWidth: 1,
                            borderColor: '#D3D3D3',
                        },
                    },
                },
                {
                    value: viewPercentages[1],
                    name: `${tfs[1]}`,
                    title: {
                        offsetCenter: ['0%', '90%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: "#000000"
                    },
                    detail: {
                        offsetCenter: ['0%', '110%'],
                        fontSize: "11px",
                        formatter: () => `${viewPercentages?.length > 0 ? viewPercentages[1] : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '70%',
                        width: 5,
                        itemStyle: {
                            color: 'auto',
                            borderWidth: 1,
                            borderColor: '#D3D3D3',
                        }
                    }
                },
                {
                    value: viewPercentages[2],
                    name: `${tfs[2]}`,
                    title: {
                        offsetCenter: ['110%', '90%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: "#000000"
                    },
                    detail: {
                        offsetCenter: ['110%', '110%'],
                        fontSize: "11px",
                        formatter: () => `${viewPercentages?.length > 0 ? viewPercentages[2] : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '90%',
                        width: 3,
                        itemStyle: {
                            color: 'auto',
                            borderWidth: 1,
                            borderColor: '#D3D3D3',
                        }
                    }
                },
                {
                    value: meanValue,
                    name: getHold(meanValue),
                    title: {
                        offsetCenter: ['0%', '67%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: getColor(meanValue)
                    },
                    detail: {
                        offsetCenter: ['0%', '50%'],
                        fontSize: "11px",
                        formatter: `${meanValue > 0 ? meanValue : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '0%',
                        width: 0,
                    }
                }
            ];
        };

        const updateChart = () => {
            const dom = chartRef.current;
            const myChart = echarts.init(dom, null, {
                renderer: 'canvas',
                useDirtyRect: false
            });

            const gaugeData = calculateGaugeData();

            const option = {
                series: [
                    {
                        type: 'gauge',
                        axisLine: {
                            lineStyle: {
                                width: 10,
                                color: [
                                    [0.382, '#da2424'],  // Red for 0% - 38.2%
                                    [0.618, '#ffb200e3'], // Orange for 38.2% - 61.8%
                                    [1, '#44db67'],      // Green for 61.8% - 100%
                                ],
                            }
                        },
                        pointer: {
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: {
                            distance: 0,
                            length: 8,
                            lineStyle: {
                                color: '#fff',
                                width: 2
                            }
                        },
                        splitLine: {
                            distance: 0,
                            length: 30,
                            lineStyle: {
                                color: '#fff',
                                width: 4
                            }
                        },
                        axisLabel: {
                            color: 'inherit',
                            distance: 32,
                            fontSize: 0
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: () => `${props?.value ? props?.value : 0}%`,
                            color: 'inherit',
                            fontSize: 8
                        },
                        data: gaugeData
                    }
                ]
            };

            myChart.setOption(option);

            // Cleanup when component unmounts
            return () => {
                window.removeEventListener('resize', myChart.resize);
                myChart.dispose();
            };
        };

        updateChart();

        window.addEventListener('resize', chartRef.current?.resize);

        return () => {
            window.removeEventListener('resize', chartRef.current?.resize);
        };
    }, [value, viewPercentages, high, low])

    const getHold = (v) => {
        if (v <= 38.2) return "Bearish"
        // else if (v > 20 && v <= 40) return "Bearish+"
        else if (v > 61.8 ) return "Bullish"
        else {
            return "Neutral"
        } 
    }

    const getColor = (v) => {  
        if (v <= 38.2) return "#da2424"
        else if (v >= 61.8 ) return "#339249"
        else { return "#ffa100e3"}
        
    }
const getMeanArrow= (arr)=>{
    let v = (Number(arr[0]) + Number(arr[1]) + Number(arr[2])) / 3
    if (v <= 38.2) {
             return (
                 <>
                     <ArrowDownward style={{ fontSize: "18px", color: "#DA2424" }} />
 
                 </>
             );
         }
        
         else if (v >= 61.8) {
             return (
                 <>
                     <ArrowUpward color="secondary" style={{ fontSize: "18px", color: "#339249" }} />
                 </>
             )
         }
         else {
             return (
                 <>
 
                     <Height style={{ rotate: "90deg", color: " #b5812a" }} />
                 </>
             )
         }
}

    const getShortAndLong = (l_prec, prev_range, previousClose, open) => {
        
        let percentage = (prev_range + l_prec) / 2

        if ((percentage <= 38.2 && (cmp < previousClose || cmp < open))) {
            return 'No Long';
        }
        else if ((percentage >= 61.8 && (cmp > previousClose || cmp > open))) {
            return 'No Short'
        }
        else {
            return 'No Action';
        }

    }
    const getShortAndLongColor = (l_prec, prev_range, previousClose, open) => {
        let percentage = (prev_range + l_prec) / 2
        if ((percentage <= 38.2 && ((cmp < previousClose) || (cmp < open)))) return '#da2424';
        else if ((percentage >= 61.8 && ((cmp > previousClose) || (cmp > open)))) return '#339249';
        else {
            return '#ffa100e3';
        }
    }


    const getMean = (arr) => {
        let v = (Number(arr[0]) + Number(arr[1]) + Number(arr[2])) / 3
        return Number.isFinite(v) ? v?.toFixed(2) : 0
    }

    return (
        <div style={{ position: 'relative', }}>
            <div ref={chartRef} className='-mt-4 -mb-10' style={{ width: '100%', height: '180px' }}></div>
            <Grid className='flex mt-7 px-4 w-full'>
                <Typography style={{ color: getColor(viewPercentages?.length > 0 ? viewPercentages[0] : 0) }} className='text-sm font-black font-futura w-1/3' align='center' >{getHold(viewPercentages?.length > 0 ? viewPercentages[0] : 0)}</Typography >
                <Typography style={{ color: getColor(viewPercentages?.length > 0 ? viewPercentages[1] : 0) }} className='text-sm font-black font-futura w-1/3' align='center'>{getHold(viewPercentages?.length > 0 ? viewPercentages[1] : 0)}</Typography >
                <Typography style={{ color: getColor(viewPercentages?.length > 0 ? viewPercentages[2] : 0) }} className='text-sm font-black font-futura w-1/3' align='center'>{getHold(viewPercentages?.length > 0 ? viewPercentages[2] : 0)}</Typography >
            </Grid>
            <Grid className='flex px-4 w-full'>
                <Typography style={{ color: getShortAndLongColor(daily_prec, tradaRange?.[0], previousclose?.[0], traderOpen?.[0]) }} className='text-sm font-black font-futura w-1/3' align='center' >{getShortAndLong(daily_prec, tradaRange?.[0], previousclose?.[0], traderOpen?.[0])}</Typography >
                <Typography style={{ color: getShortAndLongColor(weekely_prec, tradaRange?.[1], previousclose?.[1], traderOpen?.[1]) }} className='text-sm font-black font-futura w-1/3' align='center' >{getShortAndLong(weekely_prec, tradaRange?.[1], previousclose?.[1], traderOpen?.[1])}</Typography >
                <Typography style={{ color: getShortAndLongColor(monthly_prec, tradaRange?.[2], previousclose?.[2], traderOpen?.[2]) }} className='text-sm font-black font-futura w-1/3' align='center' >{getShortAndLong(monthly_prec, tradaRange?.[2], previousclose?.[2], traderOpen?.[2])}</Typography >
            </Grid>
            <div className='gaugechart-observations'>
                {getMeanArrow(viewPercentages)}
            </div>
        </div>
    );
};

export default GaugeChartNV;

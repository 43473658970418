import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import './AppHeader.css';
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";
import { fetchAllindicesData,  } from "../apiService/tickerApiService";

function HeaderTicker() {
    const [data, setData] = useState([]);

    const indiceDataForCandles = async () => {
        let _c = await fetchAllindicesData();
        setData(_c?.data);
    };

    // useEffect(() => {
    //     let intervalId = setInterval(() => {
    //         indiceDataForCandles();
    //     }, 60000);

    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);
    const sortedData = useMemo(() => {
        return [...data].sort((a, b) => {
            const getColorRank = (item) => {
                if (item?.averageIndexPercentage >= 61.8) return 3; // Green
                if (item?.averageIndexPercentage > 38.2 && item?.averageIndexPercentage < 61.8) return 2; // Gold
                return 1; // Red
            };
            return getColorRank(a) - getColorRank(b);
        });
    }, [data])
    console.log('data...', data);

    useMemo(() => {
        indiceDataForCandles();
    }, []);

    const renderCompanyDetails = (item) => (
        <Grid className="company-details" key={item.companyData?.companySymbol}>
            <Grid className="company-currentprice"
                style={{
                    color: '#fff',
                    backgroundColor: item?.averageIndexPercentage >= 61.8 ? "green" : item?.averageIndexPercentage <= 38.2 ? "red" : '#DAA520',
                    margin: "0px ",
                    fontWeight: 'bold',
                    fontFamily: 'Futura PT Heavy',
                    whiteSpace: 'nowrap',
                    padding:'1px 3px',
                    borderRadius:'7px',
                    display:"flex",
                    alignItems:'center'
                }} >
                {item.companyData?.companyName}
                {item.averageIndexPercentage >= 61.8 ? <ArrowUpward /> : item.averageIndexPercentage <= 38.2 ? <ArrowDownward /> : <Height style={{ rotate: "90deg" }} />}
                {item?.currentprice}
                ({item?.difference?.toFixed(2)})
                ({item?.changePercentage?.toFixed(2)}%)
            </Grid>
            <Grid className="company-symbol">
            </Grid>
        </Grid>

    );

    return (
        <Grid className="header-ticker">
            {sortedData.map((item) => renderCompanyDetails(item))}
            {sortedData.map((item) => renderCompanyDetails(item))}
        </Grid>
    );
}

export default HeaderTicker;

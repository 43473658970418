import { Box, debounce, FormControl, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useRef } from 'react'
import IndicesStrip from '../common/IndicesStrip'
import { useState } from 'react';
import { CurrentCompanyData, oichangeGetWeeklyDatesApi, oiChangeMonthlyApi, oiChangeWeeklyApi } from '../apiService/tickerApiService';
import { useCallback } from 'react';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons';
import Grid2 from '@mui/material/Unstable_Grid2';
import IndiceStripNew from '../common/IndiceStripNew';

const headerStyle = {
    padding: "5px ",
    color: '#fff',
    borderRight: "1px solid white",
    textAlign: "center",
    fontSize: "11px",
    backgroundColor: "#154a72"
}

const cellStyle = {
    padding: "5px ",
    borderRight: "1px solid gray",
    textAlign: "right"
}
const subHeaderStyle = {
    padding: "5px ",
    color: '#fff',
    borderRight: "1px solid white",
    textAlign: "center",
    fontSize: "14px",
    // fontWeight: 'bold'
}

function OiChangeIndices() {
    const [data, setData] = useState([])
    const [max, setMax] = useState();
    const [min, setMin] = useState();
    const [callCOIChange, setCallCOIChange] = useState(0);
    const [putCOIChange, setPutCOIChange] = useState(0);
    const [callCOI, setCallCOI] = useState(0);
    const [putCOI, setPutCOI] = useState(0);
    const [oiChange, setOiChange] = useState(0);
    const [imbalance, setImbalance] = useState(0);
    const [maxCallOIChange, setMaxCallOIChange] = useState(0);
    const [maxPutOIChange, setMaxPutOIChange] = useState(0);

    const [sumCallsOi, setSumCallsOi] = useState(0)
    const [sumPutsOi, setSumPutsOi] = useState(0)

    const [weeklyOptions, setWeeklyOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState("")
    const [tickerRes, setTickerRes] = useState(0);
    const handleChange = (event) => {
        setSelectedDate(event.target.value)
        fetchData(event.target.value)
    };

    const currentMP = async () => {
        const CMPdata = await CurrentCompanyData('256265');
        setTickerRes(CMPdata?.data)
    }
    useEffect(() => {
        const intervalId = setInterval(() => {
            currentMP();
        }, 10000);
        return () => {
            setTickerRes(0)
            clearInterval(intervalId)
        }
    }, [])


    const fetchData = async (str) => {
        let arr = str.split("/");
        let apiData;
        if (arr.length === 3) {
            const res = await oiChangeWeeklyApi(arr?.[0], arr?.[2], arr?.[1]);
            apiData = res.data.sort((a, b) => b.strikePrice - a.strikePrice);
        } else {
            const res = await oiChangeMonthlyApi(arr?.[0], arr?.[1]);
            apiData = res.data.sort((a, b) => b.strikePrice - a.strikePrice);
        }

        // Filter data based on strike price range
        const filteredData = apiData.filter((item) => item.strikePrice >= 23000 && item.strikePrice <= 23500).sort((a, b) => a.strikePrice - b.strikePrice);

        if (filteredData.length) {
            const diffOiChange = filteredData.map((el) => {
                let CEIndex = el.companies?.[0]?.companySymbol.endsWith("CE") ? 0 : 1;
                let PEIndex = el.companies?.[1]?.companySymbol.endsWith("PE") ? 1 : 0;
                return el.companies[PEIndex]?.oichange - el.companies[CEIndex]?.oichange;
            });

            const maxOi = Math.max(...diffOiChange);
            const minOi = Math.min(...diffOiChange);

            const oiChangeAvg = diffOiChange.reduce((a, b) => a + b, 0);
            const callCOIAvg = filteredData.reduce((a, b) => a + b.CallCOI, 0);
            const putCOIAvg = filteredData.reduce((a, b) => a + b.PutCOI, 0);
            const diffCOIAvg = filteredData.reduce((a, b) => a + b.Diff, 0);            

            const totalOiChange = filteredData.reduce(
                (acc, item) => {
                    const ceSum = item.companies
                        .filter((company) => company.companySymbol.endsWith("CE"))
                        .reduce((sum, company) => sum + company.oichange, 0);
                    const peSum = item.companies
                        .filter((company) => company.companySymbol.endsWith("PE"))
                        .reduce((sum, company) => sum + company.oichange, 0);

                    const ceOiSum = item.companies
                        .filter((company) => company.companySymbol.endsWith("CE"))
                        .reduce((sum, company) => sum + company.oi, 0);
                    const peOiSum = item.companies
                        .filter((company) => company.companySymbol.endsWith("PE"))
                        .reduce((sum, company) => sum + company.oi, 0);

                    return {
                        totalCE: acc.totalCE + ceSum,
                        totalPE: acc.totalPE + peSum,
                        total_CeOi: acc.total_CeOi + ceOiSum,
                        total_Peoi: acc.total_Peoi + peOiSum,
                    };
                },
                { totalCE: 0, totalPE: 0, ceArray: [], peArray: [], total_CeOi: 0, total_Peoi: 0 }
            );

            let ceOiChanges = [];
            let peOiChanges = [];

            filteredData.forEach((strike) => {
                strike.companies.forEach((company) => {
                    if (company.companySymbol.includes("CE")) {
                        ceOiChanges.push(company.oichange);
                    } else if (company.companySymbol.includes("PE")) {
                        peOiChanges.push(company.oichange);
                    }
                });
            });

            setMaxCallOIChange(Math.max(...ceOiChanges));
            setMaxPutOIChange(Math.max(...peOiChanges));

            setData(filteredData);
            setMax(maxOi);
            setMin(minOi);
            setCallCOIChange(totalOiChange.totalCE);
            setPutCOIChange(totalOiChange.totalPE);
            setSumCallsOi(totalOiChange.total_CeOi);
            setSumPutsOi(totalOiChange.total_Peoi);
            setOiChange(oiChangeAvg);
            setCallCOI((callCOIAvg * 100) / filteredData.length);
            setPutCOI((putCOIAvg * 100) / filteredData.length);
            setImbalance((diffCOIAvg * 100) / filteredData.length);
        }
    };


    const fetchWeeklyApi = async () => {
        const res = await oichangeGetWeeklyDatesApi()
        // const arr = res?.data?.map(obj => ({ label: `NIFTY50-Weekly(${obj?.expiryDate})`, value: `NIFTY50/${obj?.expiryDate}/500` }))
        const arr = res?.data?.map(obj => ({
            label: `NIFTY50-Weekly(${obj?.expiryDate})`,
            value: `NIFTY50/${obj?.expiryDate}/${1000}` // Conditional value based on 'indices'
        }));

        arr.push({ label: "NIFTY50-Monthly", value: "NIFTY50/1000" },
            // { label: "Bank Nifty-Monthly", value: "BANKNIFTY/1100" }
        )


        if (arr.length > 0) {
            setWeeklyOptions(arr)
            setSelectedDate(arr[0].value);
        }
    }

    const getRemarks = (v) => {
        if (v >= 1) {
            return "SUPPORT"
        } else {
            return "RESISTANCE"
        }
    }

    const getColor = (str) => {
        switch (str) {
            case "SUPPORT": return "#339249"
            case "RESISTANCE": return "#DA2424"
            case "NEUTRAL": return "#4D8FCC"
        }
    }

    useMemo(() => {
        fetchWeeklyApi()
        currentMP()
    }, [])

    useEffect(() => {
        if (selectedDate) {
            fetchData(selectedDate)
        }
        let intervalId = setInterval(() => {
            if (selectedDate) {
                fetchData(selectedDate)
            }
        }, 60000)

        return () => {
            clearInterval(intervalId)
        }
    }, [selectedDate])

    const getOverAllOutLook = () => {
        if ((oiChange) > 30) return "UP TREND"
        else if ((oiChange) < -30) return "DOWN TREND"
        else return "NEUTRAL"
    }

    const getOverAllOutLookColor = () => {
        if (putCOIChange < callCOIChange && sumPutsOi < sumCallsOi && putCOI < callCOI) return "#DA2424"
        else if (putCOIChange > callCOIChange && sumPutsOi > sumCallsOi && putCOI > callCOI) return "#339249"
        else return "#4D8FCC"
    }

    const getOverAllTrend = () => {
        if (putCOIChange < callCOIChange && sumPutsOi < sumCallsOi && putCOI < callCOI) return <ArrowDownward style={{ fontSize: "18px" }} />
        else if (putCOIChange > callCOIChange && sumPutsOi > sumCallsOi && putCOI > callCOI) return <ArrowUpward style={{ fontSize: "18px" }} />
        else return <Height style={{ rotate: "90deg", fontSize: "18px" }} />
    }

    const roundPercentage = (value) => {
        const decimalPart = value % 1;
        return decimalPart > 0.5 ? Math.ceil(value) : Math.floor(value);
    };
    const nearestHigherStrikePrice = (() => {

        const validStrikePrices = data
            .map((item) => item.strikePrice)
            .filter((price) => Number.isFinite(price) && price >= tickerRes?.lastTradedPrice); // Filter numbers >= cmp

        if (!validStrikePrices.length) {
            console.warn('No valid strike prices greater than or equal to CMP!');
            return null; // Fallback if no valid higher prices
        }

        return Math.min(...validStrikePrices); // Get the smallest value >= cmp
    })();

    return (
        <>
            <Box sx={{ display: "block", justifyContent: "center", }}>
                <Grid className="table-ticker" style={{ height: "2rem" }}>
                    <Grid className='flex'>
                        <FormControl className='select-dropdown' style={{ minWidth: "10rem" }}>
                            <Select value={selectedDate} onChange={handleChange}
                                labelId="demo-simple-select-label" id="demo-simple-select"
                                style={{ fontFamily: "Futura PT Book", fontWeight: "bold", display: 'flex', width: '100%', height: "2rem", fontSize: "13px" }}
                                MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" }, getContentAnchorEl: null }}>
                                {weeklyOptions.length > 0 && weeklyOptions.map((e, i) => (
                                    <MenuItem value={e.value} key={i} style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}>{e.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid className='flex'>
                        <IndiceStripNew showNifty50={true} showBankNifty={true} showNIFTYf={true} showBankNiftyF={true} />
                    </Grid>
                </Grid>
                <Grid>
                    <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 134px)` }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow key={1} >
                                    <TableCell style={{ ...headerStyle, fontWeight: "bold", backgroundColor: 'green' }} colSpan={4} align="center">
                                        {selectedDate.includes("NIFTY50") ? "NIFTY CALLS" : "BANK NIFTY CALLS"}
                                    </TableCell>
                                    <TableCell style={{ ...headerStyle }}></TableCell>
                                    <TableCell style={{ ...headerStyle, fontWeight: "bold", backgroundColor: 'red' }} colSpan={4} align="center">
                                        {/* PUTS */}
                                        {selectedDate.includes("NIFTY50") ? "NIFTY PUTS" : "BANK NIFTY PUTS"}
                                    </TableCell>
                                    <TableCell style={{ ...headerStyle, fontWeight: "bold", backgroundColor: '#905418' }} colSpan={6}>
                                        {selectedDate.includes("NIFTY50") ? "NIFTY OPTION CHAIN STATS" : "BANK NIFTY OPTION CHAIN STATS"}
                                    </TableCell>
                                    {/* <TableCell style={{ ...headerStyle, fontWeight: "bold" }} colSpan={1}></TableCell> */}
                                    {/* <TableCell style={{ ...headerStyle }}>
                                        <Grid sx={{ display: "flex" }}>
                                            <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>PCR: {sumPutsOi && (sumPutsOi / sumCallsOi)?.toFixed(2)}</Typography>
                                            <Typography noWrap style={{ fontSize: "11px", fontWeight: "bold" }}>TREND: {data?.length && getOverAllTrend()}</Typography>
                                        </Grid>
                                    </TableCell> */}
                                </TableRow>
                                <TableRow key={2}>
                                    {/* {arrHead.map((str,i) => (<TableCell key={i} style={{ ...headerStyle }}>{str}</TableCell>))} */}
                                    <TableCell style={{ ...headerStyle }}>CALL OI</TableCell>
                                    <TableCell style={{ ...headerStyle }}>CALL OI CHANGE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PRICE CHANGE%</TableCell>
                                    <TableCell style={{ ...headerStyle }}>LTP</TableCell>
                                    <TableCell style={{ ...headerStyle }}>STRIKE PRICE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>LTP</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PRICE CHANGE%</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PUT OI CHANGE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PUT OI</TableCell>
                                    <TableCell style={{ ...headerStyle }}>CALL COI %</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PUT COI %</TableCell>
                                    <TableCell style={{ ...headerStyle }}>IMBALANCE %</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PCR</TableCell>
                                    <TableCell style={{ ...headerStyle }}>STRIKE PRICE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>OI CHANGE</TableCell>
                                    {/* <TableCell style={{ ...headerStyle }}>OUTLOOK</TableCell> */}
                                </TableRow>
                                <TableRow key={3}>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} >{(sumCallsOi)}</TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} >{(callCOIChange)}</TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderBottom: "none" }} colSpan={5} ></TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} >{(putCOIChange)}</TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} >{(sumPutsOi)}</TableCell>

                                    {/* <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray", fontWeight: 'bold' }} colSpan={1}>{roundPercentage(callCOI)}%</TableCell> */}
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray", fontWeight: 'bold' }} colSpan={1}>{roundPercentage((callCOIChange/(putCOIChange+callCOIChange))*100)}%</TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray", fontWeight: 'bold' }} colSpan={1}>{roundPercentage(100-(callCOIChange/(putCOIChange+callCOIChange))*100)}%</TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: roundPercentage((100-(callCOIChange/(putCOIChange+callCOIChange))*100)-(((callCOIChange/(putCOIChange+callCOIChange))*100))) > 30 ? "green" : roundPercentage((100-(callCOIChange/(putCOIChange+callCOIChange))*100)-(((callCOIChange/(putCOIChange+callCOIChange))*100))) < -30 ? 'red' : '#c7927f', borderLeft: "1px solid gray", borderBottom: "1px solid gray", fontWeight: 'bold' }} colSpan={1}>{roundPercentage((100-(callCOIChange/(putCOIChange+callCOIChange))*100)-(((callCOIChange/(putCOIChange+callCOIChange))*100)))}%</TableCell>
                                    <TableCell style={{
                                        ...subHeaderStyle, borderBottom: "1px solid gray", textAlign: "center", background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f",
                                        //  color: (sumPutsOi / sumCallsOi) >= 1 ? "#339249" : "#DA2424"  
                                    }} colSpan={1} >{(sumPutsOi / sumCallsOi)?.toFixed(2)}</TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} ></TableCell>
                                    <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray", }} colSpan={1}>{(oiChange)}</TableCell>
                                    {/* <TableCell style={{ ...subHeaderStyle, background: getOverAllOutLook() === "DOWN TREND" ? '#c7927f' : getOverAllOutLook() === "UP TREND" ? "#c7927f" : "#c7927f", borderLeft: "1px solid gray", borderBottom: "1px solid gray", fontWeight: 'bold', textAlign: "center", fontWeight: "bold", fontSize: '12px' }} colSpan={1}>{getOverAllOutLook()}</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.length > 0 ?
                                    data?.map((el, i) => {
                                        let CEIndex = el.companies?.[0]?.companySymbol.endsWith("CE") ? 0 : 1
                                        let PEIndex = el.companies?.[1]?.companySymbol.endsWith("PE") ? 1 : 0
                                        const highestCallOi = Math.max(...data.map(el => el.companies?.[CEIndex]?.oi || 0));
                                        const highestPEOi = Math.max(...data.map(el => el.companies?.[PEIndex]?.oi || 0));
                                        return (
                                            <>
                                                <TableRow key={i}>

                                                    <TableCell style={{
                                                        ...cellStyle, borderLeft: "1px solid gray", textAlign: 'center',
                                                        // color: Number.isFinite(el.companies?.[CEIndex]?.oi) && el.companies?.[CEIndex]?.oi === highestCallOi ? "#fff" : "inherit",
                                                        // backgroundColor: Number.isFinite(el.companies?.[CEIndex]?.oi) && el.companies?.[CEIndex]?.oi === highestCallOi ? "#DA2424" : "#ef9092"
                                                        color: '#333', background: '#bed1d8'
                                                    }}>{Number.isFinite(el.companies?.[CEIndex]?.oi) ? (el.companies?.[CEIndex]?.oi) : 0}</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: el.companies?.[CEIndex]?.oichange == maxCallOIChange ? "#fff" : "#333",
                                                        // backgroundColor: el.companies?.[CEIndex]?.oichange == maxCallOIChange ? "#DA2424" : "#ef9092"
                                                        color: '#333', background: '#bed1d8'
                                                    }}>
                                                        {Number.isFinite(el.companies?.[CEIndex]?.oichange) ? (el.companies?.[CEIndex]?.oichange) : 0}
                                                    </TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: "#333", background: '#ef9092'
                                                        color: '#333', background: '#bed1d8'
                                                    }}>{Number.isFinite(el.companies?.[CEIndex]?.priceChangePercentage) ? el.companies?.[CEIndex]?.priceChangePercentage?.toFixed(2) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: "#333", background: '#ef9092'
                                                        color: '#333', background: '#bed1d8'
                                                    }}>{Number.isFinite(el.companies?.[CEIndex]?.lastTradedPrice) ? el.companies?.[CEIndex]?.lastTradedPrice?.toFixed(2) : 0}</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle,
                                                        textAlign: 'center',
                                                        fontWeight: "bold",
                                                        color: nearestHigherStrikePrice===el.strikePrice?"#fff":"#4D8FCC",
                                                        background:nearestHigherStrikePrice===el.strikePrice?"#4D8FCC":"#fff",
                                                        fontSize: "15px"
                                                    }}>
                                                        {Number.isFinite(el.strikePrice) ? el.strikePrice : 0}
                                                    </TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: "#333", background: '#D0F0C0'
                                                        color: '#333', background: '#bed1d8'
                                                    }}>{Number.isFinite(el.companies?.[PEIndex]?.lastTradedPrice) ? el.companies?.[PEIndex]?.lastTradedPrice?.toFixed(2) : 0}</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: "#333", background: '#D0F0C0'
                                                        color: '#333', background: '#bed1d8'
                                                    }}>{Number.isFinite(el.companies?.[PEIndex]?.priceChangePercentage) ? el.companies?.[PEIndex]?.priceChangePercentage?.toFixed(2) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: el.companies?.[PEIndex]?.oichange == maxPutOIChange ? "#fff" : "#333",
                                                        // backgroundColor: el.companies?.[PEIndex]?.oichange == maxPutOIChange ? "#339249" : "#D0F0C0"
                                                        color: '#333', background: '#bed1d8'
                                                    }}>
                                                        {Number.isFinite(el.companies?.[PEIndex]?.oichange) ? (el.companies?.[PEIndex]?.oichange) : 0}
                                                    </TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        // color: Number.isFinite(el.companies?.[PEIndex]?.oi) && el.companies?.[PEIndex]?.oi === highestPEOi ? "#fff" : "#333",
                                                        // backgroundColor: Number.isFinite(el.companies?.[PEIndex]?.oi) && el.companies?.[PEIndex]?.oi === highestPEOi ? "#339249" : "#D0F0C0"
                                                        color: '#333', background: '#bed1d8'
                                                    }}>
                                                        {Number.isFinite(el.companies?.[PEIndex]?.oi) ? (el.companies?.[PEIndex]?.oi) : 0}</TableCell>


                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        color: '#333', background: '#d3bc92'
                                                        // color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)),

                                                        // backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' :
                                                        // (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : 'lightgrey'
                                                    }}>{Number.isFinite(el.CallCOI) ? roundPercentage(el.CallCOI * 100) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center',
                                                        color: '#333', background: '#d3bc92'
                                                        // color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)),
                                                        // backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : 'lightgrey'
                                                    }}>{Number.isFinite(el.PutCOI) ? roundPercentage(el.PutCOI * 100) : 0}%</TableCell>


                                                    <TableCell style={{
                                                        ...cellStyle, fontSize: "15px",
                                                        color: '#333', background: '#d3bc92', textAlign: 'center',
                                                        // color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)),
                                                        // backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : 'lightgrey'
                                                    }}>{Number.isFinite(el.Diff) ? roundPercentage(el.Diff * 100) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center', fontWeight: "bold",
                                                        color: '#333', background: '#d3bc92'
                                                        // color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)),
                                                        // backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : 'lightgrey'
                                                    }}>{(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)?.toFixed(2)}</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center', fontWeight: "bold", color: "#4D8FCC", fontSize: "15px",
                                                        color: '#333', background: '#d3bc92'
                                                        // color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)),
                                                        // backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : 'lightgrey'
                                                    }}>{Number.isFinite(el.strikePrice) ? el.strikePrice : 0}</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, fontSize: "15px", fontWeight: "bold", textAlign: 'center',
                                                        color: '#333', background: '#d3bc92'
                                                        // color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.companies?.[PEIndex]?.oi / el.companies?.[CEIndex]?.oi)),
                                                        // backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : 'lightgrey'
                                                    }}>{Number.isFinite(el.companies?.[PEIndex]?.oichange) ? ((el.companies?.[PEIndex]?.oichange - el.companies?.[CEIndex]?.oichange)) : 0}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })
                                    : <TableRow>
                                        <TableCell style={{ ...cellStyle, borderBottom: "none" }} colSpan={15}>
                                            <Typography align='center'>{data?.length == 0 ? 'No Data Found' : 'Loading...'}</Typography>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}

export default OiChangeIndices
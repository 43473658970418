import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, colors } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import TableTicker from './TableTicker'
import { ArrowDropDown, ArrowDropUp, BorderBottom, BorderRight } from '@material-ui/icons';
import { getColorPr, getCurrentDate, getCurrentMonth, getCurrentWeek, getDateDurationFromQuarterOfYear, getDateDurationFromYearStart, getDateDurationFromYearStartToEnd, getDateDurationFromYearStartToHalf } from '../../utils';
import { companyApiData } from '../apiService/tradaApiService';
import Loader from '../common/Loader';
import withAuth from '../../utils/withAuth';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { tickerAPI } from '../../utils/config';
import { BankNiftyData, CurrentCompanyData, GetObservationsForCandle, ITNiftyData, NiftyData } from '../apiService/tickerApiService';
import IndicesStrip from '../common/IndicesStrip';
import SingleCandleStick from '../common/SingleCandleStick';
import MetricCandleStick from '../common/MetricCandleStick';
import GaugeChartForDatatable from '../common/GaugeChartForDatatable';
import IndiceStripNew from '../common/IndiceStripNew';

// const lregex = /L.*\d|\d.*L/;
// const hregex = /H.*\d|\d.*H/;
const hregex = /H\d+/;
const lregex = /L\d+/;

const notify = (sev, str) => toast[sev ? sev : 'info'](str);

const styles = {
  headerCell: {
    border: '1px solid white',
    color: '#333333',
    textAlign: 'start',
    // width : "200px",
    padding: "0px",
    paddingBottom: "0px"
  },
  tableCell: {
    border: '1px solid white',
    textAlign: 'center',
    color: "#ffffff",
    fontWeight: "600",
    padding: "4px"
  },
  table: {
    border: '0px solid',
    width: '100%',
    borderCollapse: 'collapse',
  },
  topContainer: {
    // display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#154A72",
    padding: "6px 5px"
  },
  timeDur: {
    fontFamily: 'Futura PT Book',
    // height: "30px",
    color: "#ffffff",
    fontWeight: 'bold',
    fontSize: "15px",
    // display: "flex",
    // width:"100%",
    alignItems: "center",
    transform: "all 1s ease-in-out",
    '&:hover': {
      transform: "scale(1.15)"
    },
  },
  greenPerc: {
    color: '#03781E',
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: '#fff',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    height: "17px",
    padding: '0px 5px'
  },
  redPerc: {
    color: '#C33E3E',
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: '#fff',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    height: "fit-content",
    padding: '0px 5px'
  },
  high: {
    backgroundColor: "#DCE9F3",
    width: "100%",
    borderRight: "1px solid white",
    borderTop: "1px solid white",
    paddingTop: "3px"
  },
  highFont: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#339249",
    fontFamily: 'Futura PT Book',
    marginLeft: "10px"
  },
  low: {
    backgroundColor: "#DCE9F3",
    width: "100%",
    borderRight: "1px solid white",
    borderTop: "1px solid white",
    paddingBottom: "3px",
    paddingTop: "3px"
  },
  lowFont: {
    fontFamily: 'Futura PT Book',
    fontSize: "12px",
    fontWeight: "bold",
    color: "#DA2424",
    marginLeft: "10px"
  },
  lastValuesInHeader: {
    backgroundColor: "#DCE9F3",
    fontFamily: 'Futura PT Book',
    fontSize: "12px",
    fontWeight: "bold",
    color: "#444444",
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '6px solid #154A72 !important'
  },
  num: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#000000"
  }
};

const getTooltip = (e) => {
  switch (e) {
    case "Yearly":
      let stry = getDateDurationFromYearStartToEnd()
      return stry
    case "Half Yearly":
      let strhy = getDateDurationFromYearStartToHalf()
      return strhy
    case "Quarterly":
      let strq = getDateDurationFromQuarterOfYear()
      return strq
    case "Monthly":
      let strm = getCurrentMonth()
      return strm
    case "Weekly":
      let strw = getCurrentWeek()
      return strw
    case "Daily":
      let strd = getCurrentDate()
      return strd
    default:
      return e
  }
}

const TableView = ({ classes, dataFromChild, loading, setLoading }) => {
  useEffect(() => {
    // Check if the Tawk.to script is present
    const tawkScript = document.querySelector('script[src="https://embed.tawk.to/66b3660832dca6db2cbaf4bb/1i4mcd4mb"]');

    // If found, remove it
    if (tawkScript) {
      tawkScript.remove();
    }

    // Ensure global Tawk.to variables are removed
    if (window.Tawk_API) {
      delete window.Tawk_API;
    }
  }, []);
  const [data, setData] = React.useState([]);
  const [Response, setResponse] = React.useState([]);
  const [ticker, setTicker] = React.useState(0);
  const [tickerResponse, setTickerResponse] = React.useState(0);
  const [closePrice, setClosePrice] = React.useState(0);
  const [niftyFifty, setniftyFifty] = React.useState(0);
  const [niftyFiftyClose, setniftyFiftyClose] = React.useState(0);
  const [bankNifty, setBankNifty] = React.useState(0);
  const [itNifty, setItNifty] = React.useState(0);
  const [prevDC, setPrevDC] = React.useState(0);
  const [prevWC, setPrevWC] = React.useState(0);
  const [prevMC, setPrevMC] = React.useState(0);
  const [prevQC, setPrecQC] = React.useState(0);
  const [prevHYC, setPrevHYC] = React.useState(0);
  const [prevYC, setPrevYC] = React.useState(0);

  const [cprtlDaily, setCprtlDaily] = React.useState(0);
  const [cprtlWeekly, setCprtlWeekly] = React.useState(0);
  const [cprtlMonthly, setCprtlMonthly] = React.useState(0);
  const [cprtl_QY, setCprtl_QY] = React.useState(0);
  const [cprtl_HY, setCprtl_HY] = React.useState(0);
  const [cprtl_Y, setCprtl_Y] = React.useState(0);

  const [cprblDaily, setCprblDaily] = React.useState(0);
  const [cprblWeekly, setCprblWeekly] = React.useState(0);
  const [cprblMonthly, setCprblMonthly] = React.useState(0);
  const [cprbl_QY, setCprbl_QY] = React.useState(0);
  const [cprbl_HY, setCprbl_HY] = React.useState(0);
  const [cprbl_Y, setCprbl_Y] = React.useState(0);


  const [nperc, setNperc] = React.useState(0);
  const [bperc, setbperc] = React.useState(0);
  const [itperc, setITperc] = React.useState(0);
  const [curr_perc, setCurr_perc] = React.useState(0);
  const [commpanyToken, setCommpanyToken] = React.useState("");
  const [keys, setKeys] = React.useState({
    dailyKeys: [],
    weeklyKeys: [],
    monthlyKeys: [],
    quarterlyKeys: [],
    halfYearlyKeys: [],
    yearlyKeys: [],
  });
  const [obj_observation_d_w, setObj_Observations_d_w] = useState({
    observe_dailyData: [],
    observe_weeklyData: [],
    observe_monthlyData: [],
  })
  const [obj_observation_rest, setObj_Observations_rest] = useState({
    observe_quaerterlyData: [],
    observe_halfYearlylyData: [],
    observe_yearlyData: []
  })
  //Dynamic Logos function
   const logohandle=(symbolname)=>{
          try{
            return (require(`../../assets/images/CounterLogos/${symbolname}.png`))
          }catch(error){
            return (null)
          }
        }
        
    const logohandledata=(e)=>{
          return(
                <Grid style={{width:"100%",display:"flex",gap:5,flexDirection:"row", justifyContent:"flex-start",alignItems:"center"}}>
                    <img style={{maxWidth:"25px",marginLeft:"5px",borderRadius:"50%"}} src={logohandle(e.value)} alt=""/> 
                   <Grid width="100px !important">{e.label}</Grid>
                </Grid>
          )
        }
  //Dynamic Logos function

  const _getObservations_d_w = async (companySymbol, t) => {
    let _dailyData = await GetObservationsForCandle("D", companySymbol, t)
    let _weeklyData = await GetObservationsForCandle("W", companySymbol, t)
    let _monthlyData = await GetObservationsForCandle("M", companySymbol, t)
    setObj_Observations_d_w({
      observe_dailyData: _dailyData?.data,
      observe_weeklyData: _weeklyData?.data,
      observe_monthlyData: _monthlyData?.data,
    })
  }

  const _getObservations_rest = async (companySymbol, t) => {
    let _quaerterlyData = await GetObservationsForCandle("Q", companySymbol, t)
    let _halfYearlylyData = await GetObservationsForCandle("H", companySymbol, t)
    let _yearlyData = await GetObservationsForCandle("Y", companySymbol, t)

    setObj_Observations_rest({
      observe_quaerterlyData: _quaerterlyData?.data,
      observe_halfYearlylyData: _halfYearlylyData?.data,
      observe_yearlyData: _yearlyData?.data
    })
  }

  const fetchCompany = async () => {
    const response = await companyApiData(dataFromChild?.companySymbol)
    if (response?.data?.success) {
      setResponse(response?.data)
      const a1 = []

      let data = response?.data

      let dailyArr = Object.values(data.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let _dailyKeys = Object.keys(data.dailyData)

      let weeklyArr = Object.values(data.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let _weeklyKeys = Object.keys(data.weeklyData)

      let monthlyArr = Object.values(data.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let _monthlyKeys = Object.keys(data.monthlyData)

      let quarterlyArr = Object.values(data.quarterlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let _quarterlyKeys = Object.keys(data.quarterlyData)

      let halfYearlyArr = Object.values(data.halfYearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let _halfYearlyKeys = Object.keys(data.halfYearlyData)

      let yearlyArr = Object.values(data.yearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let _yearlyKeys = Object.keys(data.yearlyData)

      dailyArr.map((e, i) => {
        a1.push({ daily: e, weekly: weeklyArr[i], monthly: monthlyArr[i], quarterly: quarterlyArr[i], halfyearly: halfYearlyArr[i], yearly: yearlyArr[i] })
      })

      setPrevDC(data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY?.toFixed(2))
      setPrevWC(data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK?.toFixed(2))
      setPrevMC(data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH?.toFixed(2))
      setPrecQC(data?.pre_CLOSE_QY[0]?.PRE_CLOSE_QY?.toFixed(2))
      setPrevHYC(data?.pre_CLOSE_HY[0]?.PRE_CLOSE_HY?.toFixed(2))
      setPrevYC(data?.pre_CLOSE_Y[0]?.PRE_CLOSE_Y?.toFixed(2))

      setCprtlDaily(data?.dailyData?.CPRTL_D)
      setCprtlWeekly(data?.weeklyData?.CPRTL_W)
      setCprtlMonthly(data?.monthlyData?.CPRTL_M)
      setCprtl_QY(data?.quarterlyData?.CPRTL_Q)
      setCprtl_HY(data?.halfYearlyData?.CPRTL_HY)
      setCprtl_Y(data?.yearlyData?.CPRTL_Y)

      setCprblDaily(data?.dailyData?.CPRBL_D)
      setCprblWeekly(data?.weeklyData?.CPRBL_W)
      setCprblMonthly(data?.monthlyData?.CPRBL_M)
      setCprbl_QY(data?.quarterlyData?.CPRBL_Q)
      setCprbl_HY(data?.halfYearlyData?.CPRBL_HY)
      setCprbl_Y(data?.yearlyData?.CPRBL_Y)

      setData(a1)
      setKeys({
        dailyKeys: _dailyKeys, weeklyKeys: _weeklyKeys, monthlyKeys: _monthlyKeys,
        quarterlyKeys: _quarterlyKeys, halfYearlyKeys: _halfYearlyKeys, yearlyKeys: _yearlyKeys
      })
      setLoading(false)
    } else if (response?.data?.message) {
      setLoading(false)
      setData([])
      notify("warning", `${response?.data?.message}`)
    }
  }

  const currentMP = async (token) => {
    const CMPdata = await CurrentCompanyData(token);
    setTickerResponse(CMPdata?.data)
    setTicker(CMPdata?.data?.lastTradedPrice);
    setClosePrice(CMPdata?.data?.closePrice);
    setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
  }

  useEffect(() => {
    setLoading(true)
    fetchCompany()
    const observationsInterVal_d_w = setInterval(() => {
      if (dataFromChild?.companySymbol && dataFromChild?.token) {
        _getObservations_d_w(dataFromChild?.companySymbol, dataFromChild?.token)
      }
    }, 30000);
    const observationsInterVal_rest = setInterval(() => {
      if (dataFromChild?.companySymbol && dataFromChild?.token) {
        _getObservations_rest(dataFromChild?.companySymbol, dataFromChild?.token)
      }
    }, 300000);
    const intervalId = setInterval(() => {
      if (dataFromChild?.token) {
        currentMP(dataFromChild?.token);
      } else {
        setLoading(false)
      }
    }, 2000);
    return () => {
      clearInterval(intervalId)
      clearInterval(observationsInterVal_d_w)
      clearInterval(observationsInterVal_rest)
    };
  }, [dataFromChild]);

  useMemo(() => {
    if (dataFromChild?.companySymbol && dataFromChild?.token) {
      _getObservations_d_w(dataFromChild?.companySymbol, dataFromChild?.token)
      _getObservations_rest(dataFromChild?.companySymbol, dataFromChild?.token)
    }
  }, [dataFromChild])

  const ITindex = async () => {
    const ITdata = await ITNiftyData();
    setItNifty(ITdata?.data)
    setITperc((ITdata?.data?.change)?.toFixed(2))
  }
  const BankNifty = async () => {
    const BankNiftydata = await BankNiftyData();
    setBankNifty(BankNiftydata?.data)
    setbperc((BankNiftydata?.data?.change)?.toFixed(2))
  }
  const NiftyIndex = async () => {
    const niftydata = await NiftyData();
    setniftyFifty(niftydata?.data?.lastTradedPrice)
    setniftyFiftyClose(niftydata?.data?.closePrice)
    setNperc((niftydata?.data?.change)?.toFixed(2))
  }

  const chainNifty = () => {
    BankNifty().then(() => {
      NiftyIndex().then(
        () => {
          ITindex().then(() => {
            setTimeout(() => {
              chainNifty();
            }, 2000);
          });
        }

      );
    });
  }
  let calledOnce = false;
  useEffect(() => {
    if (calledOnce) {
      return;
    }
    calledOnce = true;

    // chainNifty();
  }, [])


  const getbgColor = (e, i, _keys, data, t, findInZones) => {
    let timeF = findInZones?.timeFrame
    let row = data[i]
    switch (timeF) {
      case "dailyData":
        let val = row?.daily
        let key_d = findKeyByValue(findInZones, val)
        let color_d = _tfData(key_d, true)
        return color_d
      case "weeklyData":
        let val_w = row?.weekly
        let key_w = findKeyByValue(findInZones, val_w)
        let color_w = _tfData(key_w, true)
        return color_w
      case "monthlyData":
        let val_m = row?.monthly
        let key_m = findKeyByValue(findInZones, val_m)
        let color_m = _tfData(key_m, true)
        return color_m
      case "quarterlyData":
        let val_q = row?.quarterly
        let key_q = findKeyByValue(findInZones, val_q)
        let color_q = _tfData(key_q, true)
        return color_q
      case "halfYearlyData":
        let val_h = row?.halfyearly
        let key_h = findKeyByValue(findInZones, val_h)
        let color_h = _tfData(key_h, true)
        return color_h
      case "yearlyData":
        let val_y = row?.yearly
        let key_y = findKeyByValue(findInZones, val_y)
        let color_y = _tfData(key_y, true)
        return color_y
      default:
        return "#ffffff"
    }
  }

  const _tfData = (d, a) => {
    let color;
    switch (d) {
      case "Z1":
      case "Z2":
      case "Z8":
      case "Z9":
        color = a ? "#339249" : "#ffffff"
        return color
      case "Z3":
      case "Z4":
      case "Z10":
      case "Z11":
        color = a ? "#DA2424" : "#000000"
        return color
    }
  }

  const findValueBykey = (data, key) => {
    const d = Response[data][key]
    return d
  }

  const _findWhichZones = (data, D) => {
    let Z1 = Math.max(findValueBykey(data, `CPH6_${D}`), findValueBykey(data, `SPH3_${D}`))
    let Z2 = Math.min(findValueBykey(data, `CPH6_${D}`), findValueBykey(data, `SPH3_${D}`))
    let Z3 = Math.max(findValueBykey(data, `CPH4_${D}`), findValueBykey(data, `SPH1_${D}`), findValueBykey(data, `FPH2_${D}`))
    let Z4 = Math.min(findValueBykey(data, `CPH4_${D}`), findValueBykey(data, `SPH1_${D}`), findValueBykey(data, `FPH2_${D}`))
    // let Z5= findValueBykey(data,`CPRTL_${D}`)
    // let Z6= findValueBykey(data,`CPRPP_${D}`)
    // let Z7= findValueBykey(data,`CPRBL_${D}`)
    let Z8 = Math.max(findValueBykey(data, `CPL4_${D}`), findValueBykey(data, `SPL1_${D}`), findValueBykey(data, `FPL2_${D}`))
    let Z9 = Math.min(findValueBykey(data, `CPL4_${D}`), findValueBykey(data, `SPL1_${D}`), findValueBykey(data, `FPL2_${D}`))
    let Z10 = Math.max(findValueBykey(data, `CPL6_${D}`), findValueBykey(data, `SPL3_${D}`))
    let Z11 = Math.min(findValueBykey(data, `CPL6_${D}`), findValueBykey(data, `SPL3_${D}`))

    return { timeFrame: data, Z1, Z2, Z3, Z4, Z8, Z9, Z10, Z11 }//Z5,Z6,Z7,
  }

  const findKeyByValue = (obj, value) => {
    for (const [key, val] of Object.entries(obj)) {
      if (val == value) {
        return key;
      }
    }
    return null;
  };

  const findInZones = (tf, cell, row_i, _tf) => {
    let v = parseFloat(data[row_i][cell])
    let zone_obj = _findWhichZones(tf, _tf)
    let b = Object.values(zone_obj)
    let key = findKeyByValue(zone_obj, v)
    let color_d = _tfData(key, false)
    return color_d
  }
  const getColor = (e, i, _keys, t, findInZones, bool) => {
    let timeF = findInZones?.timeFrame
    switch (timeF) {
      case "dailyData":
        let b = Object.values(findInZones)
        let d = Object.keys(findInZones)[1]
        let c = b.some(s => s == e.daily)
        if (c) {
          let color_d = _tfData(d, false)
          return color_d
        } else {
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
        }
      case "weeklyData":
        let b_w = Object.values(findInZones)
        let d_w = Object.keys(findInZones)[1]
        let c_w = b_w.some(s => s == e.weekly)
        if (c_w) {
          let color_w = _tfData(d_w, false)
          return color_w
        } else {
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
        }
      case "monthlyData":
        let b_m = Object.values(findInZones)
        let d_m = Object.keys(findInZones)[1]
        let c_m = b_m.some(s => s == e.monthly)
        if (c_m) {
          let color_m = _tfData(d_m, false)
          return color_m
        } else {
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
        }
      case "quarterlyData":
        let b_q = Object.values(findInZones)
        let d_q = Object.keys(findInZones)[1]
        let c_q = b_q.some(s => s == e.quarterly)
        if (c_q) {
          let color_q = _tfData(d_q, false)
          return color_q
        } else {
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
        }
      case "halfYearlyData":
        let b_h = Object.values(findInZones)
        let d_h = Object.keys(findInZones)[1]
        let c_h = b_h.some(s => s == e.halfyearly)
        if (c_h) {
          let color_h = _tfData(d_h, false)
          return color_h
        } else {
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
        }
      case "yearlyData":
        let b_y = Object.values(findInZones)
        let d_y = Object.keys(findInZones)[1]
        let c_y = b_y.some(s => s == e.yearly)
        if (c_y) {
          let color_y = _tfData(d_y, false)
          return color_y
        } else {
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
        }
      default:
        let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
        return color
    }
  }

  // const getHighorLow = (index, ind, keys) => {
  //   switch (ind) {
  //     case 0:
  //       let is_value_in_zone_d = findInZones("dailyData","daily",index,"D")
  //       let key = keys?.dailyKeys[index];
  //       let colour = hregex.test(key) ? "#339249" : lregex.test(key) ? "#DA2424" : "#3E79A5";
  //       return is_value_in_zone_d ? is_value_in_zone_d : colour
  //     case 1:
  //       let is_value_in_zone_w = findInZones("weeklyData","weekly",index,"W")
  //       let wkey = keys?.weeklyKeys[index];
  //       let wcolour = hregex.test(wkey) ? "#339249" : lregex.test(wkey) ? "#DA2424" : "#3E79A5";
  //       return is_value_in_zone_w ? is_value_in_zone_w  :wcolour
  //     case 2:
  //       let is_value_in_zone_m = findInZones("monthlyData","monthly",index,"M")
  //       let mkey = keys?.monthlyKeys[index];
  //       let mcolour = hregex.test(mkey) ? "#339249" : lregex.test(mkey) ? "#DA2424" : "#3E79A5";
  //       return is_value_in_zone_m ? is_value_in_zone_m : mcolour
  //     case 3:
  //       let is_value_in_zone_q = findInZones("quarterlyData","quarterly",index,"Q")
  //       let qkey = keys?.quarterlyKeys[index];
  //       let qcolour = hregex.test(qkey) ? "#339249" : lregex.test(qkey) ? "#DA2424" : "#3E79A5";
  //       return is_value_in_zone_q ? is_value_in_zone_q : qcolour
  //     case 4:
  //       let is_value_in_zone_h = findInZones("halfYearlyData","halfyearly",index,"HY")
  //       let hykey = keys?.halfYearlyKeys[index];
  //       let hycolour = hregex.test(hykey) ? "#339249" : lregex.test(hykey) ? "#DA2424" : "#3E79A5";
  //       return is_value_in_zone_h ? is_value_in_zone_h : hycolour
  //     case 5:
  //       let is_value_in_zone_y = findInZones("yearlyData",'yearly',index,"Y")
  //       let ykey = keys?.yearlyKeys[index];
  //       let ycolour = hregex.test(ykey) ? "#339249" : lregex.test(ykey) ? "#DA2424" : "#3E79A5";
  //       return is_value_in_zone_y ? is_value_in_zone_y : ycolour
  //     default:
  //       const cprcolour = "#3E79A5";
  //       return cprcolour
  //   }
  // }

  const getObservations = (tf) => {
    switch (tf) {
      case "D":
        let d_obser = {
          arrow: obj_observation_d_w?.observe_dailyData[0]?.arrow, poscount: obj_observation_d_w?.observe_dailyData[0]?.poscount, negcount: obj_observation_d_w?.observe_dailyData[0]?.negcount,
          neutralcount: obj_observation_d_w?.observe_dailyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_dailyData[0]?.totalMetrics, rest_data: obj_observation_d_w?.observe_dailyData[0]
        }
        return d_obser
      case "W":
        let w_obser = {
          arrow: obj_observation_d_w?.observe_weeklyData[0]?.arrow, poscount: obj_observation_d_w?.observe_weeklyData[0]?.poscount, negcount: obj_observation_d_w?.observe_weeklyData[0]?.negcount,
          neutralcount: obj_observation_d_w?.observe_weeklyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_weeklyData[0]?.totalMetrics, rest_data: obj_observation_d_w?.observe_weeklyData[0]
        }
        return w_obser
      case "M":
        let m_obser = {
          arrow: obj_observation_d_w?.observe_monthlyData[0]?.arrow, poscount: obj_observation_d_w?.observe_monthlyData[0]?.poscount, negcount: obj_observation_d_w?.observe_monthlyData[0]?.negcount,
          neutralcount: obj_observation_d_w?.observe_monthlyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_monthlyData[0]?.totalMetrics, rest_data: obj_observation_d_w?.observe_monthlyData[0]
        }
        return m_obser
      case "Q":
        let q_obser = {
          arrow: obj_observation_rest?.observe_quaerterlyData[0]?.arrow, poscount: obj_observation_rest?.observe_quaerterlyData[0]?.poscount, negcount: obj_observation_rest?.observe_quaerterlyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_quaerterlyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_quaerterlyData[0]?.totalMetrics, rest_data: obj_observation_rest?.observe_quaerterlyData[0]
        }
        return q_obser
      case "H":
        let h_obser = {
          arrow: obj_observation_rest?.observe_halfYearlylyData[0]?.arrow, poscount: obj_observation_rest?.observe_halfYearlylyData[0]?.poscount, negcount: obj_observation_rest?.observe_halfYearlylyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_halfYearlylyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_halfYearlylyData[0]?.totalMetrics, rest_data: obj_observation_rest?.observe_halfYearlylyData[0]
        }
        return h_obser
      case "Y":
        let y_obser = {
          arrow: obj_observation_rest?.observe_yearlyData[0]?.arrow, poscount: obj_observation_rest?.observe_yearlyData[0]?.poscount, negcount: obj_observation_rest?.observe_yearlyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_yearlyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_yearlyData[0]?.totalMetrics, rest_data: obj_observation_rest?.observe_yearlyData[0]
        }
        return y_obser
      default:
        return {};
    }
  }

  const objHead = data.length && [
    {
      duration: "Daily",
      curr_open: tickerResponse.openPrice,
      curr_high: tickerResponse.highPrice,
      curr_low: tickerResponse.lowPrice,
      curr_range: tickerResponse.highPrice - tickerResponse.lowPrice,
      prev_Range_perc: ((ticker - Response?.dailyLowHigh[0]?.pre_low) / (Response?.dailyLowHigh[0]?.pre_high - Response?.dailyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.dailyLowHigh[0]?.pre_high,
      prev_low: Response?.dailyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY,
      observations: getObservations("D")
    },
    {
      duration: "Weekly",
      curr_open: Response?.open_WEEK[0]?.OPEN_WEEK === 0 ? tickerResponse.openPrice : Response?.open_WEEK[0]?.OPEN_WEEK,
      curr_high: (tickerResponse ? (Response.weeklyLowHigh[0].high > tickerResponse.highPrice ? Response.weeklyLowHigh[0].high : tickerResponse.highPrice) : Response.weeklyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.weeklyLowHigh[0].low < tickerResponse.lowPrice ? (Response.weeklyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.weeklyLowHigh[0].low) : tickerResponse.lowPrice) : Response.weeklyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.weeklyLowHigh[0].high > tickerResponse.highPrice ? Response.weeklyLowHigh[0].high : tickerResponse.highPrice) : Response.weeklyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.weeklyLowHigh[0].low < tickerResponse.lowPrice ? (Response.weeklyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.weeklyLowHigh[0].low) : tickerResponse.lowPrice) : Response.weeklyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_weeklyLowHigh[0]?.pre_low) / (Response?.pre_weeklyLowHigh[0]?.pre_high - Response?.pre_weeklyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_weeklyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_weeklyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK,
      observations: getObservations("W")
    },
    {
      duration: "Monthly",
      curr_open: Response?.open_MONTH[0]?.OPEN_MONTH === 0 ? tickerResponse.openPrice : Response?.open_MONTH[0]?.OPEN_MONTH,
      curr_high: (tickerResponse ? (Response.monthlyLowHigh[0].high > tickerResponse.highPrice ? Response.monthlyLowHigh[0].high : tickerResponse.highPrice) : Response.monthlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.monthlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.monthlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.monthlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.monthlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.monthlyLowHigh[0].high > tickerResponse.highPrice ? Response.monthlyLowHigh[0].high : tickerResponse.highPrice) : Response.monthlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.monthlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.monthlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.monthlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.monthlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_monthlyLowHigh[0]?.pre_low) / (Response?.pre_monthlyLowHigh[0]?.pre_high - Response?.pre_monthlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_monthlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_monthlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH,
      observations: getObservations("M")
    },
    {
      duration: "Quarterly",
      curr_open: Response?.open_QY[0]?.OPEN_QY === 0 ? tickerResponse.openPrice : Response?.open_QY[0]?.OPEN_QY,
      curr_high: (tickerResponse ? (Response.quarterlyLowHigh[0].high > tickerResponse.highPrice ? Response.quarterlyLowHigh[0].high : tickerResponse.highPrice) : Response.quarterlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.quarterlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.quarterlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.quarterlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.quarterlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.quarterlyLowHigh[0].high > tickerResponse.highPrice ? Response.quarterlyLowHigh[0].high : tickerResponse.highPrice) : Response.quarterlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.quarterlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.quarterlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.quarterlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.quarterlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_quarterlyLowHigh[0]?.pre_low) / (Response?.pre_quarterlyLowHigh[0]?.pre_high - Response?.pre_quarterlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_quarterlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_quarterlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_QY[0]?.PRE_CLOSE_QY,
      observations: getObservations("Q")
    },
    {
      duration: "Half Yearly",
      curr_open: Response?.open_HY[0]?.OPEN_HY === 0 ? tickerResponse.openPrice : Response?.open_HY[0]?.OPEN_HY,
      curr_high: (tickerResponse ? (Response.halfYearlyLowHigh[0].high > tickerResponse.highPrice ? Response.halfYearlyLowHigh[0].high : tickerResponse.highPrice) : Response.halfYearlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.halfYearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.halfYearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.halfYearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.halfYearlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.halfYearlyLowHigh[0].high > tickerResponse.highPrice ? Response.halfYearlyLowHigh[0].high : tickerResponse.highPrice) : Response.halfYearlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.halfYearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.halfYearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.halfYearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.halfYearlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_halfYearlyLowHigh[0]?.pre_low) / (Response?.pre_halfYearlyLowHigh[0]?.pre_high - Response?.pre_halfYearlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_halfYearlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_halfYearlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_HY[0]?.PRE_CLOSE_HY,
      observations: getObservations("H")
    },
    {
      duration: "Yearly",
      curr_open: Response?.open_Y[0]?.OPEN_YEAR === 0 ? tickerResponse.openPrice : Response?.open_Y[0]?.OPEN_YEAR,
      curr_high: (tickerResponse ? (Response.yearlyLowHigh[0].high > tickerResponse.highPrice ? Response.yearlyLowHigh[0].high : tickerResponse.highPrice) : Response.yearlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.yearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.yearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.yearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.yearlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.yearlyLowHigh[0].high > tickerResponse.highPrice ? Response.yearlyLowHigh[0].high : tickerResponse.highPrice) : Response.yearlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.yearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.yearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.yearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.yearlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_yearlyLowHigh[0]?.pre_low) / (Response?.pre_yearlyLowHigh[0]?.pre_high - Response?.pre_yearlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_yearlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_yearlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_Y[0]?.PRE_CLOSE_Y,
      observations: getObservations("Y")
    },
  ]

  const getPercentage = (i) => {
    switch (i) {
      case 0:
        let prec_D = ((ticker - prevDC) / prevDC) * 100
        let D_points = (ticker - closePrice)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>DTD :</p>
          <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{D_points ? D_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
        </>
      case 1:
        let prec_W = ((ticker - prevWC) / prevWC) * 100
        let W_points = (ticker - prevWC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${W_points >= 0 ? "#339249" : "#DA2424"}` }}>WTD :</p>
          <p style={{ fontSize: '11px', color: `${W_points >= 0 ? "#339249" : "#DA2424"}` }}>{W_points ? W_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_W >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_W ? prec_W?.toFixed(2) : 0}%)`}</p>
        </>
      case 2:
        let prec_M = ((ticker - prevMC) / prevMC) * 100
        let M_points = (ticker - prevMC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${M_points >= 0 ? "#339249" : "#DA2424"}` }}>MTD :</p>
          <p style={{ fontSize: '11px', color: `${M_points >= 0 ? "#339249" : "#DA2424"}` }}>{M_points ? M_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_M >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_M ? prec_M?.toFixed(2) : 0}%)`}</p>
        </>
      case 3:
        let prec_Q = ((ticker - prevQC) / prevQC) * 100
        let Q_points = (ticker - prevQC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${Q_points >= 0 ? "#339249" : "#DA2424"}` }}>QTD : </p>
          <p style={{ fontSize: '11px', color: `${Q_points >= 0 ? "#339249" : "#DA2424"}` }}>{Q_points ? Q_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_Q >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_Q ? prec_Q?.toFixed(2) : 0}%)`}</p>
        </>
      case 4:
        let prec_HY = ((ticker - prevHYC) / prevHYC) * 100
        let HY_points = (ticker - prevHYC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${HY_points >= 0 ? "#339249" : "#DA2424"}` }}>HYTD  :</p>
          <p style={{ fontSize: '11px', color: `${HY_points >= 0 ? "#339249" : "#DA2424"}` }}>{HY_points ? HY_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_HY >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_HY ? prec_HY?.toFixed(2) : 0}%)`}</p>
        </>
      case 5:
        let prec_Y = ((ticker - prevYC) / prevYC) * 100
        let Y_points = (ticker - prevYC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${Y_points >= 0 ? "#339249" : "#DA2424"}` }}>YTD : </p>
          <p style={{ fontSize: '11px', color: `${Y_points >= 0 ? "#339249" : "#DA2424"}` }}>{Y_points ? Y_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_Y >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_Y ? prec_Y?.toFixed(2) : 0}%)`}</p>
        </>
      default:
        return <p>0</p>
    }
  }

  const getHold = (v) => {
    if (v <= 20) return "Bearish++"
    else if (v > 20 && v <= 40) return "Bearish+"
    else if (v > 40 && v <= 60) return "Neutral"
    else if (v > 60 && v <= 80) return "Bullish+"
    else if (v > 80) return "Bullish++"
}
  return (
    <Grid className="ticker-and-table">
      {/* {loading && <Loader />} */}
      <Grid className="table-ticker">
        <Grid className='company-cmp'>
          <Grid style={{padding:"2px 5px",backgroundColor:"#fff",marginLeft:"5px",borderRadius:"2px"}}>
            {/* <img style={{width:"30px",marginLeft:"5px",borderRadius:"5px"}} src={img1} alt=''/> */}
            <img style={{maxWidth:"50px",minHeight:"10px" ,maxHeight:"25px",borderRadius:"2px",objectFit:"cover"}} src={logohandle(dataFromChild?.companySymbol)} alt="" />
          </Grid>
          <Grid className="details">
            <Grid className="details-context px-2 py-1"
              style={{
                color: '#fff',
                marginLeft: '0px'
              }}
            >
              {dataFromChild?.companySymbol}
            </Grid>
          </Grid>
          <Grid >
            <Grid className='pricing m-2'>
              <Grid
                style={{
                  color: `${closePrice <= ticker ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: '#fff',
                  padding: '0px 13px',
                  borderRadius: '4px',
                  //  height:'15px'
                }}>
                <p className='mr-1'>{ticker ? ticker : 0}</p>
                <p style={{ fontSize: '11px', color: '#857d7d' }}>{ticker ? (ticker - closePrice)?.toFixed(2) : 0}</p>
                <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='flex'>
          {/* <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />
          <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
          <IndicesStrip token="259849" IndiceLabel="IT NIFTY" /> */}
          <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} showNIFTYf={true}/>
        </Grid>
      </Grid>
      <Grid className="mx-1 my-1">
        <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 153px)` }}>
          {data.length ?
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {objHead.map((e, i) => {
                    return (
                      <TableCell key={i} className={classes.headerCell}>
                        <Grid container direction="column">
                          <Grid className={classes.topContainer}>
                            <Tooltip title={getTooltip(e.duration)} arrow placement="top">
                              <Typography align='center' className={classes.timeDur}>
                                {e.duration}
                              </Typography>
                            </Tooltip>
                            <Grid className={classes.greenPerc}>
                              {getPercentage(i)}
                            </Grid>
                          </Grid>
                          <Grid>
                          <GaugeChartForDatatable  value={e?.observations?.rest_data?.percentage?.toFixed(2)}  hold={getHold(e?.observations?.rest_data?.percentage?.toFixed(2))} previousclose={e?.prev_close}
                                     cmp={e?.observations?.rest_data?.currentprice} 
                                      open={e?.curr_open ? e?.curr_open : e?.ticker_open}
                                       high={e?.current_high >= e?.ticker_high ? e?.current_high : e?.ticker_high} 
                                       curr_range={e?.observations?.rest_data?.current_percentage} 
                                       low={e?.current_low >= e?.ticker_low ? e?.ticker_low : e?.current_low == 0 ? e?.ticker_low : e?.current_low} close={e?.current_close} prev_range={e?.observations?.rest_data?.pre_percentage} bg="#dfeaf2" />
                            {/* <GaugeChartForDatatable value={e?.observations?.rest_data?.percentage?.toFixed(2)} hold={getHold(e?.observations?.rest_data?.percentage?.toFixed(2))} bg="#dfeaf2"prev_range={e?.observations?.rest_data?.pre_percentage}  open={e.curr_open ? e.curr_open : 0} high={e.curr_high ? e.curr_high : 0} low={e.curr_low ? e.curr_low : 0} close={ticker?.toFixed(2)} /> */}
                          </Grid>
                          <Grid className='standard-observ-eq'>
                            {/* {ticker ? <SingleCandleStick open={e.curr_open ? e.curr_open : 0} high={e.curr_high ? e.curr_high : 0} low={e.curr_low ? e.curr_low : 0} close={ticker?.toFixed(2)} /> : ""} */}
                            {ticker ? <MetricCandleStick    cmp={e?.observations?.rest_data?.currentprice} percentage={e?.observations?.rest_data?.percentage} open={e.curr_open ? e.curr_open : 0} high={e.curr_high ? e.curr_high : 0} low={e.curr_low ? e.curr_low : 0} close={ticker?.toFixed(2)} bg="#dfeaf2"
                              poscount={e?.observations?.poscount} negcount={e?.observations?.negcount} arrow={e?.observations?.arrow} totalMetrics={e?.observations?.totalMetrics} prev_range={e?.observations?.rest_data?.pre_percentage}
                            /> : ""}
                          </Grid>
                          <Grid className='flex justify-center'>
                            <Grid className={classes.high}>
                              <Tooltip title='Previous Close' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.highFont}>P.C: {e?.prev_close ? e?.prev_close?.toFixed(2) : 0}</Typography>
                              </Tooltip>
                            </Grid>
                            <Grid className={classes.high}>
                              <Tooltip title='Current open' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.highFont}>C.O: {e?.curr_open ? e?.curr_open?.toFixed(2) : 0}</Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid className='flex justify-center'>
                            <Grid className={classes.high}>
                              <Tooltip title='Previous High' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.highFont}>P.H: {e?.prev_high ? e?.prev_high?.toFixed(2) : 0}</Typography>
                              </Tooltip>
                            </Grid>
                            <Grid className={classes.high}>
                              <Tooltip title='Current High' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.highFont}>C.H: {e?.curr_high ? e?.curr_high : 0}</Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid className='flex justify-center'>
                            <Grid className={classes.low}>
                              <Tooltip title='Previous Low' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.lowFont}>P.L: {e?.prev_low ? e?.prev_low?.toFixed(2) : 0}</Typography>
                              </Tooltip>
                            </Grid>
                            <Grid className={classes.low}>
                              <Tooltip title='Current Low' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.lowFont}>C.L: {e?.curr_low ? e?.curr_low : 0}</Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid className={classes.lastValuesInHeader}>
                            <Grid className={classes.low}>
                              <Tooltip title='Previous Range' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.highFont}>P.R: {e?.prev_low ? (e?.prev_high - e?.prev_low)?.toFixed(2) : 0}</Typography>
                              </Tooltip>
                            </Grid>
                            <Grid className={classes.high}>
                              <Tooltip title='Current Range' arrow placement="top">
                                <Typography align='center' noWrap={true} className={classes.highFont}>C.R: {e?.curr_low ? (e?.curr_high - e?.curr_low)?.toFixed(2) : 0}</Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {Object.values(item).map((value, ind) => {
                      const getColor = getHighorLow(index, ind, keys)
                      return (
                        <TableCell key={ind} variant="body" className={classes.tableCell}
                          style={{ color: getColor, border: "1px solid lightgray", backgroundColor: getbgColor(item, index, keys,data, "dailyKeys",_findWhichZones("dailyData","D")), fontFamily: 'Futura PT Book' }}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })} */}
                {data?.length && data.map((e, i) => {
                  return (
                    <TableRow className='table-row-etfs w-full' key={i}>
                      <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "dailyKeys", _findWhichZones("dailyData", "D")), backgroundColor: getbgColor(e, i, keys, data, "dailyKeys", _findWhichZones("dailyData", "D")) }}><Typography style={{ fontFamily: "Futura PT Book" }} align='center'>{e.daily}</Typography></TableCell>
                      <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "weeklyKeys", _findWhichZones("weeklyData", "W")), backgroundColor: getbgColor(e, i, keys, data, "weeklyKeys", _findWhichZones("weeklyData", "W")) }}><Typography style={{ fontFamily: "Futura PT Book" }} align='center'>{e.weekly}</Typography></TableCell>
                      <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "monthlyKeys", _findWhichZones("monthlyData", "M")), backgroundColor: getbgColor(e, i, keys, data, "monthlyKeys", _findWhichZones("monthlyData", "M")) }}><Typography style={{ fontFamily: "Futura PT Book" }} align='center'>{e.monthly}</Typography></TableCell>
                      <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "quarterlyKeys", _findWhichZones("quarterlyData", "Q")), backgroundColor: getbgColor(e, i, keys, data, "quarterlyKeys", _findWhichZones("quarterlyData", "Q")) }}><Typography style={{ fontFamily: "Futura PT Book" }} align='center'>{e.quarterly}</Typography></TableCell>
                      <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "halfYearlyKeys", _findWhichZones("halfYearlyData", "HY")), backgroundColor: getbgColor(e, i, keys, data, "halfYearlyKeys", _findWhichZones("halfYearlyData", "HY")) }}><Typography style={{ fontFamily: "Futura PT Book" }} align='center'>{e.halfyearly}</Typography></TableCell>
                      <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "yearlyKeys", _findWhichZones("yearlyData", "Y")), backgroundColor: getbgColor(e, i, keys, data, "yearlyKeys", _findWhichZones("yearlyData", "Y")) }}><Typography style={{ fontFamily: "Futura PT Book" }} align='center'>{e.yearly}</Typography></TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table> : (
              <Typography>No data found</Typography>
            )}
        </TableContainer>
      </Grid>
      <ToastContainer type="warning" position="top-right" draggable={false} pauseOnHover={false} />
    </Grid>
  );
};

export default withStyles(styles)(withAuth(TableView));

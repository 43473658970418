import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import withAuth from '../../utils/withAuth'
import OIChangeOptions from './OIChangeOptions'
import OiChangeIndices from '../Indecies/OiChangeIndices'

function OptionsIndex(props) {
    const { submenuOptions } = props


    return (
        <>
            {submenuOptions == "0" &&
                <>
                    <OiChangeIndices />
                </>
            }
            {submenuOptions == "1" &&
                <>
                    <OIChangeOptions />
                </>
            }
        </>
    )
}

export default withAuth(OptionsIndex)
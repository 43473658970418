import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import withAuth from '../../utils/withAuth'
import FutureAndOptions from './FutureAndOptions'
import Options from '../Options/Options'

function FandOIndex(props) {
    const { submenuFandO } = props

    console.log('submenuFandO..', submenuFandO);

    return (
        <>
            {submenuFandO == "0" &&
                <>
                    {/* <Indeices tabsList={indiceTabsList} /> */}
                    <FutureAndOptions />
                </>
            }
            {submenuFandO == "1" &&
                <>
                    {/* <TopTens tabsList={toptens}/> */}
                    <Options />
                </>
            }
        </>
    )
}

export default withAuth(FandOIndex)
import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react'
import "../../App.css"
import { CurrentCompanyData } from '../apiService/tickerApiService';
import IndeciesStandartView from './IndeciesStandartView';
import IndeciesTraderView from './IndeciesTradersView';
import IndeciesInvestorView from './IndeciesInvestorsView';
import IndicesStrip from '../common/IndicesStrip';
import GraphView from '../HomePage/GraphView';
import IndiceCandleGraphs from './IndiceCandleGraphs';
import IndiceCandleGraphView from './IndiceCandleGraphView';
import withAuth from '../../utils/withAuth';
import IndiceStripNew from '../common/IndiceStripNew';
import OIChangeOptions from '../OptionsOI/OIChangeOptions';
import OiChangeIndices from './OiChangeIndices';
// import img1 from "../../assets/images/Counter-Logos/nifty50.png"

const extratabs = [
  { value: "6", label: 'NIFTY100' },
  { value: "7", label: 'NIFTY200' },
  { value: "8", label: 'NIFTY500' },
  { value: "9", label: 'NIFTYMIDCAP150' },
  { value: "10", label: 'NIFTYMIDCAP50' },
  { value: "11", label: 'NIFTYMIDCAP100' },
  { value: "12", label: 'NIFTYAUTO' },
  { value: "13", label: 'NIFTYFMCG' },
  { value: "14", label: 'NIFTYCONSUMERDURABLES' },
  { value: "15", label: 'NIFTYPVTBANK' },
  { value: "16", label: 'NIFTYOILANDGAS' },
  { value: "17", label: 'NIFTYREALTY' },
  { value: "18", label: 'NIFTYPSUBANK' },
  { value: "19", label: 'NIFTYPHARMA' },
  { value: "20", label: 'NIFTYMETAL' },
  { value: "21", label: 'NIFTYMEDIA' },
  { value: "22", label: 'NIFTYHEALTHCARE' }
]


function Indecies(props) {
  const { submenuIndecies, setSubmenuEtfs, tabsList, viewTabs } = props
  const updatedTabsList = [...tabsList, ...extratabs];
  const [value, setValue] = useState(submenuIndecies ? submenuIndecies : '');
  const [label, setlabel] = useState( updatedTabsList?updatedTabsList[0].label:'');
  const [view, setView] = useState("5");

  const [tickerRes, setTickerRes] = useState(0);
  const [closePrice, setClosePrice] = useState(0);
  const [curr_perc, setCurr_perc] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    let a = updatedTabsList.filter((e, i) => e.value === newValue)
    setlabel(a.length ? a[0].label : updatedTabsList[0].label)
  };

  const currentMP = async (token) => {
    const CMPdata = await CurrentCompanyData(token);
    setTickerRes(CMPdata?.data)
    setClosePrice(CMPdata?.data?.closePrice)
    setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
  }

  useMemo(() => {
    if (submenuIndecies) {
      setValue(submenuIndecies)
      const a = tabsList.filter(e => e.value == submenuIndecies ? e.label : "")
      setlabel(a.length > 0 ? a[0].label : tabsList[0].label)
    } else {
      setValue("0");
      setlabel(tabsList[0].label)
    }
  }, [submenuIndecies])

  const handleChangeView = (e, v) => {
    setView(v)
  }

  //Dynamic Logos function
  const logohandle = (val) => {
    try {
      return (require(`../../assets/images/IndicesLogos/${filterLabel(val)}.png`))
    } catch (error) {
      return (null)
    }
  }

  const logohandledata = (e) => {
    
    return (
      <Grid style={{ width: "150px", display: "flex", gap: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
        <img style={{ maxWidth: "25px",borderRadius: "50%" }} src={logohandle(e.value)} alt="" />
        <Grid  sx={{overflowWrap: 'break-word !important' }}>{e.label}</Grid>
      </Grid>
    )
  }
  //Dynamic Logos function

  const filterLabel = (v) => {
    switch (v) {
      case "0": return "nifty50"
      case "1": return "banknifty"
      case "2": return "itnifty"
      case "3": return "finnifty"
      case "4": return "midcapselect"
      case "5": return "niftynext50"
      case "6": return "NIFTY100"
      case "7": return "NIFTY200"
      case "8": return "NIFTY500"
      case "9": return "NIFTYMIDCAP150"
      case "10": return "NIFTYMIDCAP50"
      case "11": return "NIFTYMIDCAP100"
      case "12": return "NIFTYAUTO"
      case "13": return "NIFTYFMCG"
      case "14": return "NIFTYCONSUMERDURABLES"
      case "15": return "NIFTYPVTBANK"
      case "16": return "NIFTYOILANDGAS"
      case "17": return "NIFTYREALTY"
      case "18": return "NIFTYPSUBANK"
      case "19": return "NIFTYPHARMA"
      case "20": return "NIFTYMETAL"
      case "21": return "NIFTYMEDIA"
      case "22": return "NIFTYHEALTHCARE"
      default: return ""
    }
  }
  
  const filterToken = (v) => {
    switch (v) {
      case "0": return 256265
      case "1": return 260105
      case "2": return 259849
      case "3": return 257801
      case "4": return 288009
      case "5": return 270857
      // case "6": return 0
      case "6": return 260617
      case "7": return 264457
      case "8": return 268041      // NIFTY500
      case "9":  return 266249    // NIFTYMIDCAP150
      case "10": return 260873    // NIFTYMIDCAP50
      case "11": return 256777    // NIFTYMIDCAP100
      case "12": return 263433    // NIFTYAUTO
      case "13": return 261897    // NIFTYFMCG
      case "14": return 288777    // NIFTYCONSUMERDURABLES
      case "15": return 271113    // NIFTYPVTBANK
      case "16": return 289033    // NIFTYOILANDGAS
      case "17": return 261129    // NIFTYREALTY
      case "18": return 262921    // NIFTYPSUBANK
      case "19": return 262409    // NIFTYPHARMA
      case "20": return 263689    // NIFTYMETAL
      case "21": return 263945    // NIFTYMEDIA
      case "22": return 288521    // NIFTYHEALTHCARE
      default: return 0
    }
  }
  

  useEffect(() => {
    let a = filterToken(value)
    const intervalId = setInterval(() => {
      if (submenuIndecies) {
        currentMP(a);
      }
    }, 1000);
    return () => {
      setTickerRes(0)
      clearInterval(intervalId)
    }
  }, [value])



  return (
    <Grid className="tt-container">
      <Grid className='tabs-container'>
        <Box className="tt-tablist">
          <Tabs value={value} onChange={handleChange} orientation="vertical" variant="scrollable" aria-label="Vertical tabs example" style={{ borderRight: 1, borderColor: 'divider',height:'83vh',overflow:'auto' }}>
            {updatedTabsList?.map((e, i) => (
              <Tab value={e.value} label={logohandledata(e)} key={i} className='tt-tab' />
            ))}
          </Tabs>
        </Box>
      </Grid>
      <Grid className='tbl-container' style={{ width: '87%' }}>
        <TabContext value={value}>
          <TabPanel value={value} className="tt-tablepanel">
            <Grid className="contributors">
              <Grid className="p-contributors" style={{ width: "100%" }}>
                {view !== 1 &&
                  <Grid className='bees-ticker' style={{ justifyContent: "space-between" }}>
                    {/* <Grid className='flex'>
                    <Grid className="tview-context">{label}</Grid>
                    <Grid className="tviewpricing green">
                      <p style={{ color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, }}> {tickerRes ? tickerRes?.lastTradedPrice : 0}</p>
                      <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{tickerRes ? (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2) : 0}</p>
                      <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
                    </Grid>
                  </Grid> */}
                    <Grid className='company-cmp'>
                      <Grid>
                        {/* <img style={{width:"30px",marginLeft:"5px",borderRadius:"5px"}} src={img1} alt=''/> */}
                        <img style={{ maxWidth: "20px", marginLeft: "5px", borderRadius: "3px" }} src={logohandle(value)} alt="" />
                      </Grid>
                      <Grid className="details">
                        <Grid className="details-context px-2 py-1" style={{ color: '#fff', marginLeft: '0px' }}>
                          {/* {dataFromChild?.companySymbol} */}
                          {label}
                        </Grid>
                      </Grid>
                      <Grid >
                        <Grid className='pricing m-2'>
                          <Grid
                            style={{
                              color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`,
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: '#fff',
                              padding: '0px 13px',
                              borderRadius: '4px',
                              //  height:'15px'
                            }}>
                            <p className='mr-1'>{tickerRes?.lastTradedPrice ? tickerRes?.lastTradedPrice : 0}</p>
                            <p style={{ fontSize: '11px', color: '#857d7d' }}>{tickerRes?.lastTradedPrice ? (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2) : 0}</p>
                            <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='flex'>
                      {/* {label !== "NIFTY 50" && <IndicesStrip token="256265" IndiceLabel="NIFTY50" />}
                      {label !== "BANK NIFTY" && <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />}
                      {label !== "NIFTY IT" && <IndicesStrip token="259849" IndiceLabel="IT NIFTY" />} */}
                      <IndiceStripNew
                        showNifty50={label !== "NIFTY 50"}
                        showBankNifty={label !== "BANK NIFTY"}
                        showITNifty={label !== "IT NIFTY"}
                        showBankNiftyF={label == "BANK NIFTY"}
                        showNIFTYf={label == "NIFTY 50" || label == "IT NIFTY" || label == "FIN NIFTY"}
                      />
                    </Grid>
                  </Grid>}
                {view === '1' && <IndeciesStandartView value={view} label={updatedTabsList[value].label} tickerResponse={tickerRes} IndiceValue={filterLabel(value)} token={filterToken(value)} />}
                {view === '2' && <IndeciesTraderView value={view} label={updatedTabsList[value].label} tickerResponse={tickerRes} IndiceValue={filterLabel(value)} token={filterToken(value)} />}
                {view === '3' && <IndeciesInvestorView value={view} label={updatedTabsList[value].label} tickerResponse={tickerRes} IndiceValue={filterLabel(value)} token={filterToken(value)} />}
                {view === '4' && <GraphView value={value} dataFromChild={{ token: filterToken(value), companySymbol: updatedTabsList[value].label }} showTop={false} indices={true} />}
                {/* {view === '5' && <IndiceCandleGraphView value={value} dataFromChild={{ token: filterToken(value), companySymbol: filterLabel(value)?.toUpperCase() }} showTop={false}/>} */}
                {view === '5' && <IndiceCandleGraphView value={value} dataFromChild={{ token: filterToken(value), companySymbol:  updatedTabsList[value].label }} showTop={false} cmp={tickerRes?.lastTradedPrice} />}
              </Grid>
            </Grid>
            <Grid className="investers-trading-tabs">
              <TabContext value={view}>
                <TabList className="tabs-grid"
                  onChange={handleChangeView}
                  aria-label="lab API tabs example">
                  {viewTabs.map((e, i) => (
                    <Tab key={i} className="tab-button" label={e.label} value={e.value} />
                  ))}
                </TabList>
              </TabContext>
            </Grid>
          </TabPanel>
        </TabContext>
          
      </Grid>
    </Grid>
  )
}

export default withAuth(Indecies)
import React, { useEffect, useMemo, useState } from "react";
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, FormControl, InputLabel, Grid } from "@mui/material";
import dayjs from "dayjs";
import { fetchAllInsightsFutureTrend, fetchAllInsightsTrend } from "../apiService/tickerApiService";
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";

const CompaniesTrendTable = () => {
    const [companiesData, setCompaniesData] = useState({});
    const [futuresCompaniesData, setFuturesCompaniesData] = useState({});
    const [selectedIndex, setSelectedIndex] = useState("nifty100");

    const indices = [
        // { value: "nifty50", label: "Nifty 50" },
        { value: "nifty100", label: "Nifty50 + Next50 + MidCap50" },
        { value: "niftyfuture", label: "NSE Futures" },
        // { value: "banknifty", label: "Bank Nifty" },
        // { value: "next50", label: "Next 50" },
        // { value: "itnifty", label: "IT Nifty" },
        // { value: "finnifty", label: "Fin Nifty" },
        // { value: "all", label: "All" },
    ];
console.log('companiesData...',companiesData);

    const holidays = ["2024-12-25", "2025-01-26", "2025-02-26", "2025-03-14", "2025-03-31", "2025-04-06", "2025-04-10", "2025-04-14", "2025-04-18", "2025-05-01", "2025-06-07", "2025-07-06", "2025-08-15", "2025-08-27", "2025-10-02", "2025-10-21", "2025-10-22", "2025-11-05", "2025-12-25",];

    const isHoliday = (date) => holidays.includes(date);

    const getLastWorkingDay = (date) => {
        let lastWorkingDay = dayjs(date);
        while (lastWorkingDay.day() === 0 || lastWorkingDay.day() === 6 || isHoliday(lastWorkingDay.format("YYYY-MM-DD"))) {
            lastWorkingDay = lastWorkingDay.subtract(1, "day");
        }
        return lastWorkingDay.format("YYYY-MM-DD");
    };
    // const companiesTrend = async (index) => {
    //     const currentDate_r = dayjs().format("YYYY-MM-DD");
    //     const previousDay_r = dayjs().subtract(4, "day").format("YYYY-MM-DD");

    //     const currentDay = dayjs().day(); // 0 (Sunday) - 6 (Saturday)
    //     let currentDate = dayjs().format("YYYY-MM-DD");
    //     let previousDate;

    //     if (currentDay === 6) { // Saturday
    //         currentDate = dayjs().subtract(1, "day").format("YYYY-MM-DD"); // Friday
    //         previousDate = dayjs().subtract(2, "day").format("YYYY-MM-DD"); // Thursday
    //     } else if (currentDay === 0) { // Sunday
    //         currentDate = dayjs().subtract(2, "day").format("YYYY-MM-DD"); // Friday
    //         previousDate = dayjs().subtract(3, "day").format("YYYY-MM-DD"); // Thursday
    //     } else if (currentDay === 1) { // Monday
    //         currentDate = dayjs().format("YYYY-MM-DD"); // Monday
    //         previousDate = dayjs().subtract(3, "day").format("YYYY-MM-DD"); // Friday
    //     } else {
    //         currentDate = dayjs().format("YYYY-MM-DD");
    //         previousDate = dayjs().subtract(1, "day").format("YYYY-MM-DD"); // Default to 1 day ago
    //     }

    //     // Adjust for holidays
    //     currentDate = getLastWorkingDay(currentDate);
    //     previousDate = getLastWorkingDay(previousDate);

    //     let _companies = await fetchAllInsightsTrend(index, currentDate, previousDate, currentDate_r, previousDay_r); // Pass selected index 
    //     setCompaniesData(_companies?.data);
    // };
    const companiesTrend = async (index) => {
        const currentDate_r = dayjs().format("YYYY-MM-DD");
        const previousDay_r = dayjs().subtract(4, "day").format("YYYY-MM-DD");

        const currentDay = dayjs().day();
        let currentDate = dayjs().format("YYYY-MM-DD");
        let previousDate;

        if (currentDay === 6) { // Saturday
            currentDate = dayjs().subtract(1, "day").format("YYYY-MM-DD"); // Friday
            previousDate = dayjs().subtract(2, "day").format("YYYY-MM-DD"); // Thursday
        } else if (currentDay === 0) { // Sunday
            currentDate = dayjs().subtract(2, "day").format("YYYY-MM-DD"); // Friday
            previousDate = dayjs().subtract(3, "day").format("YYYY-MM-DD"); // Thursday
        } else if (currentDay === 1) { // Monday
            currentDate = dayjs().format("YYYY-MM-DD"); // Monday
            previousDate = dayjs().subtract(3, "day").format("YYYY-MM-DD"); // Friday
        } else {
            currentDate = dayjs().format("YYYY-MM-DD");
            previousDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        }

        // Adjust for holidays
        currentDate = getLastWorkingDay(currentDate);
        previousDate = getLastWorkingDay(previousDate);

        let _companies = await fetchAllInsightsTrend();
        let _futurescompanies = await fetchAllInsightsFutureTrend(currentDate, previousDate, currentDate_r, previousDay_r);

        setCompaniesData(_companies?.data);
        setFuturesCompaniesData(_futurescompanies?.data);
    };

    useEffect(() => {
        companiesTrend(selectedIndex); // Fetch data for the default index
        let intervalId = setInterval(() => {
            companiesTrend(selectedIndex);
        }, 900000);

        return () => clearInterval(intervalId);
    }, [selectedIndex]); // Refetch data when index changes

    // useMemo(() => {
    //     companiesTrend(selectedIndex);
    // }, [])
    const handleIndexChange = (event) => {
        setSelectedIndex(event.target.value);
    };

    const getUniqueDates = () => {
        if (!companiesData.positive && !companiesData.negative) return [];
        const positiveDates =
            companiesData.positive?.flatMap((company) =>
                company.trend.map((t) => t.date)
            ) || [];
        const negativeDates =
            companiesData.negative?.flatMap((company) =>
                company.trend.map((t) => t.date)
            ) || [];
        const uniqueDates = [...new Set([...positiveDates, ...negativeDates])];
        return uniqueDates.sort((a, b) => new Date(a) - new Date(b)); // Sort dates in descending order
    };
    const getUniqueDatesFutures = () => {
        if (!futuresCompaniesData.positive && !futuresCompaniesData.negative) return [];
        const positiveDates =
            futuresCompaniesData?.positive?.flatMap((company) =>
                company.trend.map((t) => t.date)
            ) || [];
        const negativeDates =
            futuresCompaniesData.negative?.flatMap((company) =>
                company.trend.map((t) => t.date)
            ) || [];
        const uniqueDates = [...new Set([...positiveDates, ...negativeDates])];
        return uniqueDates.sort((a, b) => new Date(a) - new Date(b)); // Sort dates in descending order
    };

    const uniqueDates = getUniqueDates();
    const uniqueDates_f = getUniqueDatesFutures();

    const formatDate = (date) => {
        const options = { day: 'numeric', month: 'short' }; // 'short' for abbreviated month name
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        return formattedDate.replace(' ', '-'); // Replaces the space between month and day with a hyphen
    };
    const getArrow = (value) => {
        if (value < 38.2) return <ArrowDownward style={{ color: 'red', fontSize: '18px' }} />; // Down arrow
        if (value > 61.8) return <ArrowUpward style={{ color: 'green', fontSize: '18px' }} />; // Up arrow
        return <Height style={{ rotate: "90deg", color: " #DAA520", fontSize: '18px' }} />; // Neutral arrow
    };

    const renderTable = (companies, color, tf) => {
        if (!companies || companies.length === 0) {
            return (
                <div
                    style={{
                        marginBottom: "32px",
                        border: `2px solid ${color}`,
                        borderRadius: "8px",
                        overflow: "hidden",
                        textAlign: "center",
                        padding: "16px",
                    }}
                >
                    <p style={{ fontFamily: "Futura PT Book", fontSize: "14px", color: "#333", fontWeight: 'bold' }}>No Counters</p>
                </div>
            );
        }

        return (
            <div style={{ marginBottom: "32px", border: `2px solid ${color}`, borderRadius: "8px", overflow: "hidden" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead style={{ backgroundColor: color === "green" ? "#deffbb" : "#ffbbbb" }}>
                        <tr>
                            <th style={{ fontFamily: "Futura PT Heavy", fontSize: "15px", color, textAlign: "center", padding: "1px" }}>Symbol</th>
                            <th style={{ fontFamily: "Futura PT Heavy", fontSize: "15px", color, textAlign: "center", padding: "1px" }}>CMP</th>
                            <th style={{ fontFamily: "Futura PT Heavy", fontSize: "15px", color, textAlign: "center", padding: "1px" }}>Trend</th>
                            {uniqueDates.map((date) => (
                                <th
                                    key={date}
                                    style={{
                                        fontFamily: "Futura PT Heavy",
                                        fontSize: "15px",
                                        color,
                                        textAlign: "center",
                                        padding: "4px",
                                    }}
                                >
                                    {formatDate(dayjs(date).format("MMM DD, YYYY"))}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((company) => (
                            <tr key={company.symbol} style={{ borderBottom: '1px solid lightgray' }}>
                                <td
                                    style={{
                                        fontFamily: "Futura PT Demi",
                                        color: "#fff",
                                        // backgroundColor: color,
                                        textAlign: "center",
                                        padding: "2px",
                                        borderRadius: "7px",
                                        height: "10px",
                                    }}
                                >
                                    <span style={{ backgroundColor: color, padding: '2px', borderRadius: '6px' }}> {company.symbol}</span>
                                </td>
                                <td
                                    style={{
                                        fontFamily: "Futura PT Book",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        padding: "8px",
                                    }}
                                >
                                    {company.currentMarketPrice.toFixed(2)}
                                </td>
                                <td
                                    style={{
                                        fontFamily: "Futura PT Book",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        padding: "8px",
                                    }}
                                >
                                    <div style={{ display: "grid", justifyContent: "space-around", whiteSpace: 'nowrap' }}>
                                        {/* {tf==='st'&& <span>ST :</span>} */}
                                        <span>ST :</span>
                                        <span>MT :</span>
                                        <span>LT :</span>
                                    </div>
                                </td>
                                {uniqueDates.map((date) => {
                                    const trend = company.trend.find((t) => t.date === date);
                                    return (
                                        <td
                                            key={date}
                                            style={{
                                                fontFamily: "Futura PT Book",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                padding: "8px",
                                            }}
                                        >
                                            <div style={{ display: "grid", justifyContent: "space-around" }}>
                                                {/* {tf==='st'&&  <span> */}
                                                <span style={{ background: tf === 'st' ? '#FFF' : '', borderRadius: '4px', padding: '1px', }}>
                                                    {trend
                                                        ? getArrow(
                                                            (trend.dailyPercentage +
                                                                trend.weeklyPercentage +
                                                                trend.monthlyPercentage) /
                                                            3
                                                        )
                                                        : "-"}
                                                </span>
                                                <span style={{ background: tf === 'mt' ? '#fff' : '', borderRadius: '4px', padding: '1px', }}>
                                                    {trend
                                                        ? getArrow(
                                                            (trend.monthlyPercentage +
                                                                trend.quaterlyPercentage +
                                                                trend.halfyearlyPercentage) /
                                                            3
                                                        )
                                                        : "-"}
                                                </span>
                                                <span style={{ background: tf === 'lt' ? '#fff' : '', borderRadius: '4px', padding: '1px', }}>
                                                    {trend
                                                        ? getArrow(
                                                            (trend.quaterlyPercentage +
                                                                trend.halfyearlyPercentage +
                                                                trend.yearlyPercentage) /
                                                            3
                                                        )
                                                        : "-"}
                                                </span>
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    const renderTableFutures = (companies, color, tf) => {
        if (!companies || companies.length === 0) {
            return (
                <div
                    style={{
                        marginBottom: "32px",
                        border: `2px solid ${color}`,
                        borderRadius: "8px",
                        overflow: "hidden",
                        textAlign: "center",
                        padding: "16px",
                    }}
                >
                    <p style={{ fontFamily: "Futura PT Book", fontSize: "14px", color: "#333", fontWeight: 'bold' }}>No Counters</p>
                </div>
            );
        }

        return (
            <div style={{ marginBottom: "32px", border: `2px solid ${color}`, borderRadius: "8px", overflow: "hidden" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead style={{ backgroundColor: color === "green" ? "#deffbb" : "#ffbbbb" }}>
                        <tr>
                            <th style={{ fontFamily: "Futura PT Heavy", fontSize: "15px", color, textAlign: "center", padding: "1px" }}>Symbol</th>
                            <th style={{ fontFamily: "Futura PT Heavy", fontSize: "15px", color, textAlign: "center", padding: "1px" }}>CMP</th>
                            {uniqueDates_f.map((date) => (
                                <th
                                    key={date}
                                    style={{
                                        fontFamily: "Futura PT Heavy",
                                        fontSize: "15px",
                                        color,
                                        textAlign: "center",
                                        padding: "4px",
                                    }}
                                >
                                    {formatDate(dayjs(date).format("MMM DD, YYYY"))}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((company) => (
                            <tr key={company.symbol} style={{ borderBottom: '1px solid lightgray' }}>
                                <td
                                    style={{
                                        fontFamily: "Futura PT Demi",
                                        color: "#fff",
                                        // backgroundColor: color,
                                        textAlign: "center",
                                        padding: "2px",
                                        borderRadius: "7px",
                                        height: "10px",
                                    }}
                                >
                                    <span style={{ backgroundColor: color, padding: '2px', borderRadius: '6px' }}> {company.symbol}</span>
                                </td>
                                <td
                                    style={{
                                        fontFamily: "Futura PT Book",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        padding: "8px",
                                    }}
                                >
                                    {company.currentMarketPrice.toFixed(2)}
                                </td>

                                {uniqueDates_f.map((date) => {
                                    const trend = company.trend.find((t) => t.date === date);
                                    return (
                                        <td
                                            key={date}
                                            style={{
                                                fontFamily: "Futura PT Book",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                padding: "8px",
                                            }}
                                        >
                                            <div style={{ display: "grid", justifyContent: "space-around" }}>
                                                {/* {tf==='st'&&  <span> */}
                                                <span style={{ background: tf === 'st' ? '#FFF' : '', borderRadius: '4px', padding: '1px', }}>
                                                    {trend
                                                        ? getArrow(
                                                            (trend.dailyPercentage +
                                                                trend.weeklyPercentage +
                                                                trend.monthlyPercentage) /
                                                            3
                                                        )
                                                        : "-"}
                                                </span>
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    console.log('futuresCompaniesData', futuresCompaniesData);


    return (
        <div style={{ width: "100%", marginTop: "10px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    // marginTop: "10px",
                    width: "100%",
                    borderBottom: "1px solid gray",
                }}
            >
                <p style={{ fontFamily: "Futura PT Heavy", textAlign: "center", fontSize: "18px", margin: "0" }}>
                    Trend Changing Counters
                </p>

                {/* Dropdown for Indices */}
                <div style={{ width: "23%", display: 'flex' }}>
                    <label htmlFor="index-select" style={{ fontSize: "13px", display: "flex", marginBottom: "5px", alignItems: 'center', whiteSpace: 'nowrap' }}>
                        Select Index :
                    </label>
                    <select
                        id="index-select"
                        value={selectedIndex}
                        onChange={handleIndexChange}
                        style={{
                            width: "100%",
                            fontSize: "13px",
                            padding: "5px", border: '1px solid lightgray', marginBottom: "5px", borferRadius: '5px'
                        }}
                    >
                        {indices.map((index) => (
                            <option key={index.value} value={index.value}>
                                {index.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Main Content */}
            {selectedIndex === 'nifty100' ? <div
                style={{
                    // height: "70vh",
                    // overflowY: "scroll",
                    display: "flex",
                    gap: '1%'
                }}
            >
                {/* First Set of Positive and Negative Counters */}
                <div style={{ width: "33%", background: "aliceblue", padding: '5px' }}>
                    <h6 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 'bold', background: '#05416c', color: '#fff', padding: '2px', borderRadius: '4px' }}>Short Term</h6>
                    <div style={{}}>
                        <p
                            style={{
                                fontFamily: "Futura PT Heavy",
                                color: "green",
                                fontSize: "16px",
                                marginBottom: "5px",
                            }}
                        >
                            Positive Counters
                        </p>
                        {renderTable(companiesData.positive, "green", 'st')}
                    </div>

                    <div style={{}}>
                        <p
                            style={{
                                fontFamily: "Futura PT Heavy",
                                color: "red",
                                fontSize: "16px",
                                marginBottom: "5px",
                            }}
                        >
                            Negative Counters
                        </p>
                        {renderTable(companiesData.negative, "red", 'st')}
                    </div>
                </div>

                {/* Second Set of Positive and Negative Counters */}
                <div style={{ width: "33%", background: " #E8F4FF", padding: '5px' }}>
                    <h6 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 'bold', background: '#05416c', color: '#fff', padding: '2px', borderRadius: '4px' }}>Mid Term</h6>
                    <div style={{}}>
                        <p
                            style={{
                                fontFamily: "Futura PT Heavy",
                                color: "green",
                                fontSize: "16px",
                                marginBottom: "5px",
                            }}
                        >
                            Positive Counters
                        </p>
                        {renderTable(companiesData.midpositive, "green", 'mt')}
                    </div>

                    <div style={{}}>
                        <p
                            style={{
                                fontFamily: "Futura PT Heavy",
                                color: "red",
                                fontSize: "16px",
                                marginBottom: "5px",
                            }}
                        >
                            Negative Counters
                        </p>
                        {renderTable(companiesData.midnegative, "red", 'mt')}
                    </div>
                </div>

                {/* Third Set of Positive and Negative Counters */}
                <div style={{ width: "33%", background: "#E0F0FF", padding: '5px' }}>
                    <h6 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 'bold', background: '#05416c', color: '#fff', padding: '2px', borderRadius: '4px' }}>Long Term</h6>
                    <div style={{}}>
                        <p
                            style={{
                                fontFamily: "Futura PT Heavy",
                                color: "green",
                                fontSize: "16px",
                                marginBottom: "5px",
                            }}
                        >
                            Positive Counters
                        </p>
                        {renderTable(companiesData.longpositive, "green", 'lt')}
                    </div>

                    <div style={{}}>
                        <p
                            style={{
                                fontFamily: "Futura PT Heavy",
                                color: "red",
                                fontSize: "16px",
                                marginBottom: "5px",
                            }}
                        >
                            Negative Counters
                        </p>
                        {renderTable(companiesData.longnegative, "red", 'lt')}
                    </div>
                </div>
            </div>
                :

                <>
                    <h6 style={{ display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 'bold', background: '#05416c', color: '#fff', padding: '2px', borderRadius: '4px' }}>NSE Futures</h6>
                    <div style={{ display: 'flex', background: "aliceblue", padding: '5px', margin: 'auto' }}>


                        <div style={{ width: '50%' }}>
                            <p
                                style={{
                                    fontFamily: "Futura PT Heavy",
                                    color: "red",
                                    fontSize: "16px",
                                    marginBottom: "5px",
                                }}
                            >
                                Negative Counters
                            </p>
                            {renderTableFutures(futuresCompaniesData.negative, "red", 'st')}
                        </div>

                        <div style={{ width: '50%' }}>
                            <p
                                style={{
                                    fontFamily: "Futura PT Heavy",
                                    color: "green",
                                    fontSize: "16px",
                                    marginBottom: "5px",
                                }}
                            >
                                Positive Counters
                            </p>
                            {renderTableFutures(futuresCompaniesData.positive, "green", 'stf')}
                        </div>


                    </div>
                </>
            }
        </div>

    );
};

export default CompaniesTrendTable;
